import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import SchoolImage from "assets/defaultSignup/SchoolImage.png";
import TeacherImage from "assets/defaultSignup/TeacherImage.png";
import SelectAccountButton from "features/root/components/molecules/defaultSignup/SelectAccountButton";
import useQueryParams from "hooks/useQueryParams";
import { activeStateAtom } from "store/DefaultSignupStore";

import type React from "react";

type QueryParamsType = {
  active?: "" | "teacher" | "school";
};

const SelectAccountSection: React.FC = () => {
  const [activeState, setActiveState] = useAtom(activeStateAtom);
  const { setQueryParams } = useQueryParams<QueryParamsType>();

  const handleActiveState = (state: "" | "teacher" | "school") => {
    setActiveState(state);
    setQueryParams({ active: state });
  };

  return (
    <Box
      sx={{
        margin: { xs: "1rem auto", sm: "1.5rem auto" },
        display: "flex",
        gap: { xs: ".5rem", sm: "1rem" },
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box sx={{ width: "100%" }}>
        <SelectAccountButton
          accountName="複業先生アカウント"
          title="複業先生になる"
          desc={["複業先生になって", "授業をやりたい"]}
          image={TeacherImage}
          isActive={activeState === "teacher"}
          handleActiveState={() => handleActiveState("teacher")}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <SelectAccountButton
          accountName="教員アカウント"
          title="授業依頼をする"
          desc={["複業先生を導入したい", "教職員の方"]}
          image={SchoolImage}
          isActive={activeState === "school"}
          handleActiveState={() => handleActiveState("school")}
        />
      </Box>
    </Box>
  );
};
export default SelectAccountSection;
