import React from "react";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import TeacherInfoBlock from "features/schools/components/molecules/show/requests/TeacherInfoBlock";
import Button from "components/atoms/mui/Button";

import { teacherAtom, detailsAtom } from "features/schools/store/requestsStore";
import RequestInfoBlock from "components/molecules/RequestInfoBlock";

const LessonHold: React.FC = () => {
  const details = useAtomValue(detailsAtom);
  const teacher = useAtomValue(teacherAtom);
  const history = useHistory();
  const theme = useTheme();

  const handleClick = () => {
    history.push(`/schools/mypage/lessons/${details?.lesson_id}`);
  };

  return (
    <Box>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box>
            <Typography fontWeight="bold" fontSize="1rem">
              授業が成立しました！
            </Typography>
            <Typography>複業先生とやり取りして、授業を進めましょう</Typography>
          </Box>
          <Button onClick={handleClick}>授業進行管理画面へ</Button>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        {details?.step === "denied" && (
          <Box sx={{ margin: "0 auto 1rem" }}>
            <Typography fontSize=".8rem" color={theme.palette.sub.main}>
              {teacher?.name}さんからのメッセージ
            </Typography>
            <Typography>{details?.reply_comment}</Typography>
          </Box>
        )}
        <TeacherInfoBlock />
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        <RequestInfoBlock />
      </Box>
    </Box>
  );
};

export default LessonHold;
