import type { AspidaClient, BasicHeaders } from "aspida";
import { dataToURLString } from "aspida";
import type { Methods as Methods_4cyq2g } from "./api/v1/batchs/send_encourage_completion_reports_mail";
import type { Methods as Methods_u2usi0 } from "./api/v1/batchs/send_reminder_lesson_accept_mail";
import type { Methods as Methods_11cuda2 } from "./api/v1/batchs/send_select_candidate_mail";
import type { Methods as Methods_ww7wor } from "./api/v1/lesson_recruitments";
import type { Methods as Methods_9i1ho5 } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string";
import type { Methods as Methods_1oaal9x } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/cancel_recruitment";
import type { Methods as Methods_18hi0uc } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates";
import type { Methods as Methods_w7vafx } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates/cancel_candidate";
import type { Methods as Methods_1um51kr } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/manual_close";
import type { Methods as Methods_2exo47 } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/select_teacher";
import type { Methods as Methods_289od6 } from "./api/v1/lesson_recruitments/new";
import type { Methods as Methods_19j59pu } from "./api/v1/lesson_requests";
import type { Methods as Methods_m6twiq } from "./api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import type { Methods as Methods_2xam2h } from "./api/v1/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_su63zp } from "./api/v1/lesson_requests/_lessonRequestId@string/generate_teaching_plan";
import type { Methods as Methods_h80tyf } from "./api/v1/lesson_requests/_lessonRequestId@string/lesson_request_comments/_lessonRequestCommentId@string/logical_delete";
import type { Methods as Methods_13kl9c7 } from "./api/v1/lesson_requests/new";
import type { Methods as Methods_ilozoo } from "./api/v1/lessons/_lessonId/agenda_sheets/_agendaSheetId@string";
import type { Methods as Methods_18if7a4 } from "./api/v1/lessons/_lessonId/agenda_sheets/new";
import type { Methods as Methods_10rzm40 } from "./api/v1/lessons/_lessonId@string";
import type { Methods as Methods_oau4c9 } from "./api/v1/lessons/_lessonId@string/lesson_complete";
import type { Methods as Methods_q2j5xm } from "./api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes";
import type { Methods as Methods_86y4th } from "./api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes/select_datetime";
import type { Methods as Methods_1gil0j5 } from "./api/v1/lessons/_lessonId@string/ratings";
import type { Methods as Methods_18wf6ga } from "./api/v1/lessons/_lessonId@string/student_questionnaires/new";
import type { Methods as Methods_1exwf0n } from "./api/v1/lessons/_lessonId@string/timelines";
import type { Methods as Methods_8liqj0 } from "./api/v1/lessons/_lessonId@string/timelines/_timelineId@string";
import type { Methods as Methods_4l7s7r } from "./api/v1/lessons/_lessonId@string/timelines/create_default_data";
import type { Methods as Methods_oyoh9b } from "./api/v1/lessons/_lessonId@string/timelines/multi_update";
import type { Methods as Methods_wegie9 } from "./api/v1/master_financial_institutions";
import type { Methods as Methods_t0d2xj } from "./api/v1/master_financial_institutions/_financialInstitutionCode@string/branches";
import type { Methods as Methods_kpn2az } from "./api/v1/master_schools";
import type { Methods as Methods_128uobd } from "./api/v1/school_teacher_auth";
import type { Methods as Methods_1tc3x4f } from "./api/v1/school_teacher_auth/password";
import type { Methods as Methods_1gmp8zz } from "./api/v1/school_teacher_auth/sign_in";
import type { Methods as Methods_1pe3510 } from "./api/v1/school_teacher_auth/sign_out";
import type { Methods as Methods_quxard } from "./api/v1/school_teacher_profiles";
import type { Methods as Methods_1eklzky } from "./api/v1/school_teacher_profiles/_schoolTeacherProfileId@string";
import type { Methods as Methods_1k7yp8b } from "./api/v1/school_teacher_profiles/_schoolTeacherProfileId@string/edit";
import type { Methods as Methods_mqmywc } from "./api/v1/school_teacher_profiles/new";
import type { Methods as Methods_1hz11rn } from "./api/v1/school_teachers/_schoolTeacherId@number/school_announcements/_schoolTeacherAnnouncementId@number/announcement_check";
import type { Methods as Methods_yra74p } from "./api/v1/school_teachers/_schoolTeacherId@string";
import type { Methods as Methods_16de948 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments";
import type { Methods as Methods_qqf0c4 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments/_lessonRecrutimentId@string";
import type { Methods as Methods_1n7b18l } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_requests";
import type { Methods as Methods_1fmdwj6 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_z03hnj } from "./api/v1/school_teachers/_schoolTeacherId@string/lessons";
import type { Methods as Methods_gyl7et } from "./api/v1/school_teachers/_schoolTeacherId@string/lessons/_lessonId@string";
import type { Methods as Methods_b080et } from "./api/v1/school_teachers/_schoolTeacherId@string/lessons/no_rating";
import type { Methods as Methods_18k9n0h } from "./api/v1/school_teachers/_schoolTeacherId@string/school_announcements";
import type { Methods as Methods_68vypb } from "./api/v1/school_teachers/login_school_teacher";
import type { Methods as Methods_1nfgpxm } from "./api/v1/teacher_auth";
import type { Methods as Methods_yapgnq } from "./api/v1/teacher_auth/password";
import type { Methods as Methods_7h4cis } from "./api/v1/teacher_auth/sign_in";
import type { Methods as Methods_lzrqp } from "./api/v1/teacher_auth/sign_out";
import type { Methods as Methods_3ia6mm } from "./api/v1/teacher_profiles";
import type { Methods as Methods_e1ycuv } from "./api/v1/teacher_profiles/_teacherProfileId@string";
import type { Methods as Methods_1b882u4 } from "./api/v1/teacher_profiles/_teacherProfileId@string/edit";
import type { Methods as Methods_1b87kkx } from "./api/v1/teacher_profiles/_teacherProfileId@string/judge_request";
import type { Methods as Methods_1ovggn7 } from "./api/v1/teacher_profiles/new";
import type { Methods as Methods_jvfwzs } from "./api/v1/teachers";
import type { Methods as Methods_1ico1hg } from "./api/v1/teachers/_teacherId@number/teacher_announcements";
import type { Methods as Methods_1336rzc } from "./api/v1/teachers/_teacherId@number/teacher_announcements/_teacherAnnouncementId@number/announcement_check";
import type { Methods as Methods_2uegaq } from "./api/v1/teachers/_teacherId@string";
import type { Methods as Methods_gtxoyu } from "./api/v1/teachers/_teacherId@string/e_learnings";
import type { Methods as Methods_1hk2anv } from "./api/v1/teachers/_teacherId@string/e_learnings/new";
import type { Methods as Methods_7lhx1f } from "./api/v1/teachers/_teacherId@string/lesson_recruitments";
import type { Methods as Methods_1gehdre } from "./api/v1/teachers/_teacherId@string/lesson_requests";
import type { Methods as Methods_123jsc1 } from "./api/v1/teachers/_teacherId@string/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_1p36wu8 } from "./api/v1/teachers/_teacherId@string/lessons";
import type { Methods as Methods_dibnew } from "./api/v1/teachers/_teacherId@string/lessons/_lessonId@string";
import type { Methods as Methods_3u3trf } from "./api/v1/teachers/_teacherId@string/teacher_bank_accounts";
import type { Methods as Methods_1syykh8 } from "./api/v1/teachers/_teacherId@string/teacher_bank_accounts/_teacherBankAccountId@string";
import type { Methods as Methods_e0322r } from "./api/v1/teachers/_teacherId@string/teacher_identifications";
import type { Methods as Methods_1l9446c } from "./api/v1/teachers/_teacherId@string/teacher_videos";
import type { Methods as Methods_108k0v3 } from "./api/v1/teachers/_teacherId@string/teacher_videos/_teacherVideoId@string";
import type { Methods as Methods_lpu3cj } from "./api/v1/teachers/login_teacher";
import type { Methods as Methods_1t202l5 } from "./api/v1/teachers/lp_classi";
import type { Methods as Methods_16ra1rl } from "./api/v1/teaching_plan_ideas";
import type { Methods as Methods_1on40ck } from "./api/v1/teaching_plan_ideas/new";
import type { Methods as Methods_b7n32a } from "./api/v1/tools/teacher_common_data";
import type { Methods as Methods_5vgulz } from "./api/v1/webhooks/trustdock";

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (
    baseURL === undefined ? "http://localhost:3000" : baseURL
  ).replace(/\/$/, "");
  const PATH0 = "/api/v1/batchs/send_encourage_completion_reports_mail";
  const PATH1 = "/api/v1/batchs/send_reminder_lesson_accept_mail";
  const PATH2 = "/api/v1/batchs/send_select_candidate_mail";
  const PATH3 = "/api/v1/lesson_recruitments";
  const PATH4 = "/cancel_recruitment";
  const PATH5 = "/candidates";
  const PATH6 = "/candidates/cancel_candidate";
  const PATH7 = "/manual_close";
  const PATH8 = "/select_teacher";
  const PATH9 = "/api/v1/lesson_recruitments/new";
  const PATH10 = "/api/v1/lesson_requests";
  const PATH11 = "/lesson_request_comments";
  const PATH12 = "/generate_teaching_plan";
  const PATH13 = "/logical_delete";
  const PATH14 = "/api/v1/lesson_requests/new";
  const PATH15 = "/api/v1/lessons";
  const PATH16 = "/agenda_sheets";
  const PATH17 = "/agenda_sheets/new";
  const PATH18 = "/lesson_complete";
  const PATH19 = "/lesson_meeting_possible_datetimes";
  const PATH20 = "/lesson_meeting_possible_datetimes/select_datetime";
  const PATH21 = "/ratings";
  const PATH22 = "/student_questionnaires/new";
  const PATH23 = "/timelines";
  const PATH24 = "/timelines/create_default_data";
  const PATH25 = "/timelines/multi_update";
  const PATH26 = "/api/v1/master_financial_institutions";
  const PATH27 = "/branches";
  const PATH28 = "/api/v1/master_schools";
  const PATH29 = "/api/v1/school_teacher_auth";
  const PATH30 = "/api/v1/school_teacher_auth/password";
  const PATH31 = "/api/v1/school_teacher_auth/sign_in";
  const PATH32 = "/api/v1/school_teacher_auth/sign_out";
  const PATH33 = "/api/v1/school_teacher_profiles";
  const PATH34 = "/edit";
  const PATH35 = "/api/v1/school_teacher_profiles/new";
  const PATH36 = "/api/v1/school_teachers";
  const PATH37 = "/school_announcements";
  const PATH38 = "/announcement_check";
  const PATH39 = "/lesson_recruitments";
  const PATH40 = "/lesson_requests";
  const PATH41 = "/lessons";
  const PATH42 = "/lessons/no_rating";
  const PATH43 = "/api/v1/school_teachers/login_school_teacher";
  const PATH44 = "/api/v1/teacher_auth";
  const PATH45 = "/api/v1/teacher_auth/password";
  const PATH46 = "/api/v1/teacher_auth/sign_in";
  const PATH47 = "/api/v1/teacher_auth/sign_out";
  const PATH48 = "/api/v1/teacher_profiles";
  const PATH49 = "/judge_request";
  const PATH50 = "/api/v1/teacher_profiles/new";
  const PATH51 = "/api/v1/teachers";
  const PATH52 = "/teacher_announcements";
  const PATH53 = "/e_learnings";
  const PATH54 = "/e_learnings/new";
  const PATH55 = "/teacher_bank_accounts";
  const PATH56 = "/teacher_identifications";
  const PATH57 = "/teacher_videos";
  const PATH58 = "/api/v1/teachers/login_teacher";
  const PATH59 = "/api/v1/teachers/lp_classi";
  const PATH60 = "/api/v1/teaching_plan_ideas";
  const PATH61 = "/api/v1/teaching_plan_ideas/new";
  const PATH62 = "/api/v1/tools/teacher_common_data";
  const PATH63 = "/api/v1/webhooks/trustdock";
  const GET = "GET";
  const POST = "POST";
  const PUT = "PUT";
  const DELETE = "DELETE";
  const PATCH = "PATCH";

  return {
    api: {
      v1: {
        batchs: {
          send_encourage_completion_reports_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_4cyq2g["get"]["resBody"],
                BasicHeaders,
                Methods_4cyq2g["get"]["status"]
              >(prefix, PATH0, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_4cyq2g["get"]["resBody"],
                BasicHeaders,
                Methods_4cyq2g["get"]["status"]
              >(prefix, PATH0, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH0}`
          },
          send_reminder_lesson_accept_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_u2usi0["get"]["resBody"],
                BasicHeaders,
                Methods_u2usi0["get"]["status"]
              >(prefix, PATH1, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_u2usi0["get"]["resBody"],
                BasicHeaders,
                Methods_u2usi0["get"]["status"]
              >(prefix, PATH1, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH1}`
          },
          send_select_candidate_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_11cuda2["get"]["resBody"],
                BasicHeaders,
                Methods_11cuda2["get"]["status"]
              >(prefix, PATH2, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_11cuda2["get"]["resBody"],
                BasicHeaders,
                Methods_11cuda2["get"]["status"]
              >(prefix, PATH2, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH2}`
          }
        },
        lesson_recruitments: {
          _lessonRecruitmentId: (val3: string) => {
            const prefix3 = `${PATH3}/${val3}`;

            return {
              cancel_recruitment: {
                /**
                 * @returns 授業募集のキャンセル
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1oaal9x["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1oaal9x["patch"]["status"]
                  >(prefix, `${prefix3}${PATH4}`, PATCH, option).json(),
                /**
                 * @returns 授業募集のキャンセル
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1oaal9x["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1oaal9x["patch"]["status"]
                  >(prefix, `${prefix3}${PATH4}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH4}`
              },
              candidates: {
                cancel_candidate: {
                  /**
                   * @returns 授業応募のキャンセル
                   */
                  patch: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_w7vafx["patch"]["resBody"],
                      BasicHeaders,
                      Methods_w7vafx["patch"]["status"]
                    >(prefix, `${prefix3}${PATH6}`, PATCH, option).json(),
                  /**
                   * @returns 授業応募のキャンセル
                   */
                  $patch: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_w7vafx["patch"]["resBody"],
                      BasicHeaders,
                      Methods_w7vafx["patch"]["status"]
                    >(prefix, `${prefix3}${PATH6}`, PATCH, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH6}`
                },
                /**
                 * @returns 授業募集への応募
                 */
                post: (option: {
                  body: Methods_18hi0uc["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_18hi0uc["post"]["resBody"],
                    BasicHeaders,
                    Methods_18hi0uc["post"]["status"]
                  >(prefix, `${prefix3}${PATH5}`, POST, option).json(),
                /**
                 * @returns 授業募集への応募
                 */
                $post: (option: {
                  body: Methods_18hi0uc["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_18hi0uc["post"]["resBody"],
                    BasicHeaders,
                    Methods_18hi0uc["post"]["status"]
                  >(prefix, `${prefix3}${PATH5}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH5}`
              },
              manual_close: {
                /**
                 * @returns 授業募集早期終了
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1um51kr["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1um51kr["patch"]["status"]
                  >(prefix, `${prefix3}${PATH7}`, PATCH, option).json(),
                /**
                 * @returns 授業募集早期終了
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1um51kr["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1um51kr["patch"]["status"]
                  >(prefix, `${prefix3}${PATH7}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH7}`
              },
              select_teacher: {
                /**
                 * @returns 授業募集複業先生選択
                 */
                patch: (option: {
                  body: Methods_2exo47["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_2exo47["patch"]["resBody"],
                    BasicHeaders,
                    Methods_2exo47["patch"]["status"]
                  >(prefix, `${prefix3}${PATH8}`, PATCH, option).json(),
                /**
                 * @returns 授業募集複業先生選択
                 */
                $patch: (option: {
                  body: Methods_2exo47["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_2exo47["patch"]["resBody"],
                    BasicHeaders,
                    Methods_2exo47["patch"]["status"]
                  >(prefix, `${prefix3}${PATH8}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH8}`
              },
              /**
               * @returns 募集詳細取得
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_9i1ho5["get"]["resBody"],
                  BasicHeaders,
                  Methods_9i1ho5["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 募集詳細取得
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_9i1ho5["get"]["resBody"],
                  BasicHeaders,
                  Methods_9i1ho5["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 募集作成時のデータ取得
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_289od6["get"]["resBody"],
                BasicHeaders,
                Methods_289od6["get"]["status"]
              >(prefix, PATH9, GET, option).json(),
            /**
             * @returns 募集作成時のデータ取得
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_289od6["get"]["resBody"],
                BasicHeaders,
                Methods_289od6["get"]["status"]
              >(prefix, PATH9, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH9}`
          },
          /**
           * @returns 授業募集作成
           */
          post: (option: {
            body: Methods_ww7wor["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_ww7wor["post"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["post"]["status"]
            >(prefix, PATH3, POST, option).json(),
          /**
           * @returns 授業募集作成
           */
          $post: (option: {
            body: Methods_ww7wor["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_ww7wor["post"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["post"]["status"]
            >(prefix, PATH3, POST, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 授業募集一覧取得
           */
          get: (
            option?:
              | {
                  query?: Methods_ww7wor["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_ww7wor["get"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["get"]["status"]
            >(prefix, PATH3, GET, option).json(),
          /**
           * @returns 授業募集一覧取得
           */
          $get: (
            option?:
              | {
                  query?: Methods_ww7wor["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_ww7wor["get"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["get"]["status"]
            >(prefix, PATH3, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_ww7wor["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        lesson_requests: {
          _lessonRequestId: (val3: number | string) => {
            const prefix3 = `${PATH10}/${val3}`;

            return {
              lesson_request_comments: {
                /**
                 * @returns 授業のチャット取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_m6twiq["get"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["get"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, GET, option).json(),
                /**
                 * @returns 授業のチャット取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_m6twiq["get"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["get"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 授業チャット送信
                 */
                post: (option: {
                  body: Methods_m6twiq["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_m6twiq["post"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["post"]["status"]
                  >(
                    prefix,
                    `${prefix3}${PATH11}`,
                    POST,
                    option,
                    "FormData"
                  ).json(),
                /**
                 * @returns 授業チャット送信
                 */
                $post: (option: {
                  body: Methods_m6twiq["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_m6twiq["post"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["post"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, POST, option, "FormData")
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH11}`
              }
            };
          },
          _lessonRequestId_string: (val3: string) => {
            const prefix3 = `${PATH10}/${val3}`;

            return {
              generate_teaching_plan: {
                /**
                 * @returns 授業依頼の授業案作成
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_su63zp["post"]["resBody"],
                    BasicHeaders,
                    Methods_su63zp["post"]["status"]
                  >(prefix, `${prefix3}${PATH12}`, POST, option).json(),
                /**
                 * @returns 授業依頼の授業案作成
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_su63zp["post"]["resBody"],
                    BasicHeaders,
                    Methods_su63zp["post"]["status"]
                  >(prefix, `${prefix3}${PATH12}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH12}`
              },
              lesson_request_comments: {
                _lessonRequestCommentId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH11}/${val5}`;

                  return {
                    logical_delete: {
                      /**
                       * @returns 授業チャットの論理削除
                       */
                      patch: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_h80tyf["patch"]["resBody"],
                          BasicHeaders,
                          Methods_h80tyf["patch"]["status"]
                        >(prefix, `${prefix5}${PATH13}`, PATCH, option).json(),
                      /**
                       * @returns 授業チャットの論理削除
                       */
                      $patch: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_h80tyf["patch"]["resBody"],
                          BasicHeaders,
                          Methods_h80tyf["patch"]["status"]
                        >(prefix, `${prefix5}${PATH13}`, PATCH, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH13}`
                    }
                  };
                }
              },
              /**
               * @returns 授業依頼の更新
               */
              put: (option: {
                body: Methods_2xam2h["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_2xam2h["put"]["resBody"],
                  BasicHeaders,
                  Methods_2xam2h["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 授業依頼の更新
               */
              $put: (option: {
                body: Methods_2xam2h["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_2xam2h["put"]["resBody"],
                  BasicHeaders,
                  Methods_2xam2h["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 授業依頼時のデータ取得
             */
            get: (
              option?:
                | {
                    query?: Methods_13kl9c7["get"]["query"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<
                Methods_13kl9c7["get"]["resBody"],
                BasicHeaders,
                Methods_13kl9c7["get"]["status"]
              >(prefix, PATH14, GET, option).json(),
            /**
             * @returns 授業依頼時のデータ取得
             */
            $get: (
              option?:
                | {
                    query?: Methods_13kl9c7["get"]["query"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<
                Methods_13kl9c7["get"]["resBody"],
                BasicHeaders,
                Methods_13kl9c7["get"]["status"]
              >(prefix, PATH14, GET, option)
                .json()
                .then((r) => r.body),
            $path: (
              option?:
                | {
                    method?: "get" | undefined;
                    query: Methods_13kl9c7["get"]["query"];
                  }
                | undefined
            ) =>
              `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
          },
          /**
           * @returns 授業依頼作成
           */
          post: (option: {
            body: Methods_19j59pu["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_19j59pu["post"]["resBody"],
              BasicHeaders,
              Methods_19j59pu["post"]["status"]
            >(prefix, PATH10, POST, option).json(),
          /**
           * @returns 授業依頼作成
           */
          $post: (option: {
            body: Methods_19j59pu["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_19j59pu["post"]["resBody"],
              BasicHeaders,
              Methods_19j59pu["post"]["status"]
            >(prefix, PATH10, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        lessons: {
          _lessonId: (val3: number | string) => {
            const prefix3 = `${PATH15}/${val3}`;

            return {
              agenda_sheets: {
                _agendaSheetId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH16}/${val5}`;

                  return {
                    /**
                     * @returns 授業準備シート更新
                     */
                    put: (option: {
                      body: Methods_ilozoo["put"]["reqBody"];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_ilozoo["put"]["resBody"],
                        BasicHeaders,
                        Methods_ilozoo["put"]["status"]
                      >(prefix, prefix5, PUT, option).json(),
                    /**
                     * @returns 授業準備シート更新
                     */
                    $put: (option: {
                      body: Methods_ilozoo["put"]["reqBody"];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_ilozoo["put"]["resBody"],
                        BasicHeaders,
                        Methods_ilozoo["put"]["status"]
                      >(prefix, prefix5, PUT, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                new: {
                  /**
                   * @returns 授業準備シートの取得
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_18if7a4["get"]["resBody"],
                      BasicHeaders,
                      Methods_18if7a4["get"]["status"]
                    >(prefix, `${prefix3}${PATH17}`, GET, option).json(),
                  /**
                   * @returns 授業準備シートの取得
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_18if7a4["get"]["resBody"],
                      BasicHeaders,
                      Methods_18if7a4["get"]["status"]
                    >(prefix, `${prefix3}${PATH17}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH17}`
                }
              }
            };
          },
          _lessonId_string: (val3: string) => {
            const prefix3 = `${PATH15}/${val3}`;

            return {
              lesson_complete: {
                /**
                 * @returns 授業完了
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_oau4c9["post"]["resBody"],
                    BasicHeaders,
                    Methods_oau4c9["post"]["status"]
                  >(prefix, `${prefix3}${PATH18}`, POST, option).json(),
                /**
                 * @returns 授業完了
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_oau4c9["post"]["resBody"],
                    BasicHeaders,
                    Methods_oau4c9["post"]["status"]
                  >(prefix, `${prefix3}${PATH18}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH18}`
              },
              lesson_meeting_possible_datetimes: {
                select_datetime: {
                  /**
                   * @returns 授業打ち合わせ候補日時選択
                   */
                  post: (option: {
                    body: Methods_86y4th["post"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_86y4th["post"]["resBody"],
                      BasicHeaders,
                      Methods_86y4th["post"]["status"]
                    >(prefix, `${prefix3}${PATH20}`, POST, option).json(),
                  /**
                   * @returns 授業打ち合わせ候補日時選択
                   */
                  $post: (option: {
                    body: Methods_86y4th["post"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_86y4th["post"]["resBody"],
                      BasicHeaders,
                      Methods_86y4th["post"]["status"]
                    >(prefix, `${prefix3}${PATH20}`, POST, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH20}`
                },
                /**
                 * @returns 授業打ち合わせ候補日時追加
                 */
                post: (option: {
                  body: Methods_q2j5xm["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_q2j5xm["post"]["resBody"],
                    BasicHeaders,
                    Methods_q2j5xm["post"]["status"]
                  >(prefix, `${prefix3}${PATH19}`, POST, option).json(),
                /**
                 * @returns 授業打ち合わせ候補日時追加
                 */
                $post: (option: {
                  body: Methods_q2j5xm["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_q2j5xm["post"]["resBody"],
                    BasicHeaders,
                    Methods_q2j5xm["post"]["status"]
                  >(prefix, `${prefix3}${PATH19}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH19}`
              },
              ratings: {
                /**
                 * @returns 授業評価データ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1gil0j5["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gil0j5["get"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, GET, option).json(),
                /**
                 * @returns 授業評価データ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1gil0j5["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gil0j5["get"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 評価登録
                 */
                post: (option: {
                  body: Methods_1gil0j5["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1gil0j5["post"]["resBody"],
                    BasicHeaders,
                    Methods_1gil0j5["post"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, POST, option).json(),
                /**
                 * @returns 評価登録
                 */
                $post: (option: {
                  body: Methods_1gil0j5["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1gil0j5["post"]["resBody"],
                    BasicHeaders,
                    Methods_1gil0j5["post"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH21}`
              },
              student_questionnaires: {
                new: {
                  /**
                   * @returns 生徒向けアンケート登録
                   */
                  get: (option: {
                    query: Methods_18wf6ga["get"]["query"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_18wf6ga["get"]["resBody"],
                      BasicHeaders,
                      Methods_18wf6ga["get"]["status"]
                    >(prefix, `${prefix3}${PATH22}`, GET, option).json(),
                  /**
                   * @returns 生徒向けアンケート登録
                   */
                  $get: (option: {
                    query: Methods_18wf6ga["get"]["query"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_18wf6ga["get"]["resBody"],
                      BasicHeaders,
                      Methods_18wf6ga["get"]["status"]
                    >(prefix, `${prefix3}${PATH22}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: (
                    option?:
                      | {
                          method?: "get" | undefined;
                          query: Methods_18wf6ga["get"]["query"];
                        }
                      | undefined
                  ) =>
                    `${prefix}${prefix3}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
                }
              },
              timelines: {
                _timelineId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH23}/${val5}`;

                  return {
                    /**
                     * @returns タイムラインの削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_8liqj0["delete"]["resBody"],
                        BasicHeaders,
                        Methods_8liqj0["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns タイムラインの削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_8liqj0["delete"]["resBody"],
                        BasicHeaders,
                        Methods_8liqj0["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                create_default_data: {
                  /**
                   * @returns タイムラインのデフォルトデータ作成
                   */
                  post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_4l7s7r["post"]["resBody"],
                      BasicHeaders,
                      Methods_4l7s7r["post"]["status"]
                    >(prefix, `${prefix3}${PATH24}`, POST, option).json(),
                  /**
                   * @returns タイムラインのデフォルトデータ作成
                   */
                  $post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_4l7s7r["post"]["resBody"],
                      BasicHeaders,
                      Methods_4l7s7r["post"]["status"]
                    >(prefix, `${prefix3}${PATH24}`, POST, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH24}`
                },
                multi_update: {
                  /**
                   * @returns タイムラインの一括更新
                   */
                  patch: (option: {
                    body: Methods_oyoh9b["patch"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_oyoh9b["patch"]["resBody"],
                      BasicHeaders,
                      Methods_oyoh9b["patch"]["status"]
                    >(prefix, `${prefix3}${PATH25}`, PATCH, option).json(),
                  /**
                   * @returns タイムラインの一括更新
                   */
                  $patch: (option: {
                    body: Methods_oyoh9b["patch"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_oyoh9b["patch"]["resBody"],
                      BasicHeaders,
                      Methods_oyoh9b["patch"]["status"]
                    >(prefix, `${prefix3}${PATH25}`, PATCH, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH25}`
                },
                /**
                 * @returns タイムラインの作成
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1exwf0n["post"]["resBody"],
                    BasicHeaders,
                    Methods_1exwf0n["post"]["status"]
                  >(prefix, `${prefix3}${PATH23}`, POST, option).json(),
                /**
                 * @returns タイムラインの作成
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1exwf0n["post"]["resBody"],
                    BasicHeaders,
                    Methods_1exwf0n["post"]["status"]
                  >(prefix, `${prefix3}${PATH23}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH23}`
              },
              /**
               * @returns 授業情報の更新
               */
              patch: (option: {
                body: Methods_10rzm40["patch"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_10rzm40["patch"]["resBody"],
                  BasicHeaders,
                  Methods_10rzm40["patch"]["status"]
                >(prefix, prefix3, PATCH, option).json(),
              /**
               * @returns 授業情報の更新
               */
              $patch: (option: {
                body: Methods_10rzm40["patch"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_10rzm40["patch"]["resBody"],
                  BasicHeaders,
                  Methods_10rzm40["patch"]["status"]
                >(prefix, prefix3, PATCH, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          }
        },
        master_financial_institutions: {
          _financialInstitutionCode: (val3: string) => {
            const prefix3 = `${PATH26}/${val3}`;

            return {
              branches: {
                /**
                 * @returns 支店一覧取得
                 */
                get: (option: {
                  query: Methods_t0d2xj["get"]["query"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_t0d2xj["get"]["resBody"],
                    BasicHeaders,
                    Methods_t0d2xj["get"]["status"]
                  >(prefix, `${prefix3}${PATH27}`, GET, option).json(),
                /**
                 * @returns 支店一覧取得
                 */
                $get: (option: {
                  query: Methods_t0d2xj["get"]["query"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_t0d2xj["get"]["resBody"],
                    BasicHeaders,
                    Methods_t0d2xj["get"]["status"]
                  >(prefix, `${prefix3}${PATH27}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_t0d2xj["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              }
            };
          },
          /**
           * @returns 金融機関一覧取得
           */
          get: (option: {
            query: Methods_wegie9["get"]["query"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_wegie9["get"]["resBody"],
              BasicHeaders,
              Methods_wegie9["get"]["status"]
            >(prefix, PATH26, GET, option).json(),
          /**
           * @returns 金融機関一覧取得
           */
          $get: (option: {
            query: Methods_wegie9["get"]["query"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_wegie9["get"]["resBody"],
              BasicHeaders,
              Methods_wegie9["get"]["status"]
            >(prefix, PATH26, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_wegie9["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH26}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        master_schools: {
          /**
           * @returns 学校マスター取得
           */
          get: (
            option?:
              | {
                  query?: Methods_kpn2az["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_kpn2az["get"]["resBody"],
              BasicHeaders,
              Methods_kpn2az["get"]["status"]
            >(prefix, PATH28, GET, option).json(),
          /**
           * @returns 学校マスター取得
           */
          $get: (
            option?:
              | {
                  query?: Methods_kpn2az["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_kpn2az["get"]["resBody"],
              BasicHeaders,
              Methods_kpn2az["get"]["status"]
            >(prefix, PATH28, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_kpn2az["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        school_teacher_auth: {
          password: {
            /**
             * @returns 学校教員パスワード変更
             */
            put: (option: {
              body: Methods_1tc3x4f["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["put"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["put"]["status"]
              >(prefix, PATH30, PUT, option).json(),
            /**
             * @returns 学校教員パスワード変更
             */
            $put: (option: {
              body: Methods_1tc3x4f["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["put"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["put"]["status"]
              >(prefix, PATH30, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns 学校教員パスワード変更リクエスト
             */
            post: (option: {
              body: Methods_1tc3x4f["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["post"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["post"]["status"]
              >(prefix, PATH30, POST, option).json(),
            /**
             * @returns 学校教員パスワード変更リクエスト
             */
            $post: (option: {
              body: Methods_1tc3x4f["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["post"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["post"]["status"]
              >(prefix, PATH30, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH30}`
          },
          sign_in: {
            /**
             * @returns 学校教員ログイン
             */
            post: (option: {
              body: Methods_1gmp8zz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gmp8zz["post"]["resBody"],
                Methods_1gmp8zz["post"]["resHeaders"],
                Methods_1gmp8zz["post"]["status"]
              >(prefix, PATH31, POST, option).json(),
            /**
             * @returns 学校教員ログイン
             */
            $post: (option: {
              body: Methods_1gmp8zz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gmp8zz["post"]["resBody"],
                Methods_1gmp8zz["post"]["resHeaders"],
                Methods_1gmp8zz["post"]["status"]
              >(prefix, PATH31, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH31}`
          },
          sign_out: {
            /**
             * @returns 学校教員ログアウト
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1pe3510["delete"]["resBody"],
                BasicHeaders,
                Methods_1pe3510["delete"]["status"]
              >(prefix, PATH32, DELETE, option).json(),
            /**
             * @returns 学校教員ログアウト
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1pe3510["delete"]["resBody"],
                BasicHeaders,
                Methods_1pe3510["delete"]["status"]
              >(prefix, PATH32, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH32}`
          },
          /**
           * @returns 学校教員認証系レスポンス
           */
          patch: (option: {
            body: Methods_128uobd["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["patch"]["resBody"],
              BasicHeaders,
              Methods_128uobd["patch"]["status"]
            >(prefix, PATH29, PATCH, option).json(),
          /**
           * @returns 学校教員認証系レスポンス
           */
          $patch: (option: {
            body: Methods_128uobd["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["patch"]["resBody"],
              BasicHeaders,
              Methods_128uobd["patch"]["status"]
            >(prefix, PATH29, PATCH, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 学校教員認証系レスポンス
           */
          post: (option: {
            body: Methods_128uobd["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["post"]["resBody"],
              BasicHeaders,
              Methods_128uobd["post"]["status"]
            >(prefix, PATH29, POST, option).json(),
          /**
           * @returns 学校教員認証系レスポンス
           */
          $post: (option: {
            body: Methods_128uobd["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["post"]["resBody"],
              BasicHeaders,
              Methods_128uobd["post"]["status"]
            >(prefix, PATH29, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH29}`
        },
        school_teacher_profiles: {
          _schoolTeacherProfileId: (val3: string) => {
            const prefix3 = `${PATH33}/${val3}`;

            return {
              edit: {
                /**
                 * @returns 学校教員更新データ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1k7yp8b["get"]["resBody"],
                    BasicHeaders,
                    Methods_1k7yp8b["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option).json(),
                /**
                 * @returns 学校教員更新データ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1k7yp8b["get"]["resBody"],
                    BasicHeaders,
                    Methods_1k7yp8b["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH34}`
              },
              /**
               * @returns 学校教員プロフィール更新
               */
              put: (option: {
                body: Methods_1eklzky["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1eklzky["put"]["resBody"],
                  BasicHeaders,
                  Methods_1eklzky["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 学校教員プロフィール更新
               */
              $put: (option: {
                body: Methods_1eklzky["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1eklzky["put"]["resBody"],
                  BasicHeaders,
                  Methods_1eklzky["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 学校教員プロフィール登録
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_mqmywc["get"]["resBody"],
                BasicHeaders,
                Methods_mqmywc["get"]["status"]
              >(prefix, PATH35, GET, option).json(),
            /**
             * @returns 学校教員プロフィール登録
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_mqmywc["get"]["resBody"],
                BasicHeaders,
                Methods_mqmywc["get"]["status"]
              >(prefix, PATH35, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH35}`
          },
          /**
           * @returns 学校教員プロフィール登録
           */
          post: (option: {
            body: Methods_quxard["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_quxard["post"]["resBody"],
              BasicHeaders,
              Methods_quxard["post"]["status"]
            >(prefix, PATH33, POST, option).json(),
          /**
           * @returns 学校教員プロフィール登録
           */
          $post: (option: {
            body: Methods_quxard["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_quxard["post"]["resBody"],
              BasicHeaders,
              Methods_quxard["post"]["status"]
            >(prefix, PATH33, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH33}`
        },
        school_teachers: {
          _schoolTeacherId_number: (val3: number) => {
            const prefix3 = `${PATH36}/${val3}`;

            return {
              school_announcements: {
                _schoolTeacherAnnouncementId: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH37}/${val5}`;

                  return {
                    announcement_check: {
                      /**
                       * @returns OK
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<
                          Methods_1hz11rn["post"]["resBody"],
                          BasicHeaders,
                          Methods_1hz11rn["post"]["status"]
                        >(prefix, `${prefix5}${PATH38}`, POST, option).json(),
                      /**
                       * @returns OK
                       */
                      $post: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_1hz11rn["post"]["resBody"],
                          BasicHeaders,
                          Methods_1hz11rn["post"]["status"]
                        >(prefix, `${prefix5}${PATH38}`, POST, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH38}`
                    }
                  };
                }
              }
            };
          },
          _schoolTeacherId_string: (val3: string) => {
            const prefix3 = `${PATH36}/${val3}`;

            return {
              lesson_recruitments: {
                _lessonRecrutimentId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH39}/${val5}`;

                  return {
                    /**
                     * @returns 学校に紐づく授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_qqf0c4["get"]["resBody"],
                        BasicHeaders,
                        Methods_qqf0c4["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 学校に紐づく授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_qqf0c4["get"]["resBody"],
                        BasicHeaders,
                        Methods_qqf0c4["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 学校教員作成授業募集一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_16de948["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_16de948["get"]["resBody"],
                    BasicHeaders,
                    Methods_16de948["get"]["status"]
                  >(prefix, `${prefix3}${PATH39}`, GET, option).json(),
                /**
                 * @returns 学校教員作成授業募集一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_16de948["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_16de948["get"]["resBody"],
                    BasicHeaders,
                    Methods_16de948["get"]["status"]
                  >(prefix, `${prefix3}${PATH39}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_16de948["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH39}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lesson_requests: {
                _lessonRequestId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH40}/${val5}`;

                  return {
                    /**
                     * @returns 学校教員向け授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1fmdwj6["get"]["resBody"],
                        BasicHeaders,
                        Methods_1fmdwj6["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 学校教員向け授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1fmdwj6["get"]["resBody"],
                        BasicHeaders,
                        Methods_1fmdwj6["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 学校教員向け授業依頼一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_1n7b18l["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1n7b18l["get"]["resBody"],
                    BasicHeaders,
                    Methods_1n7b18l["get"]["status"]
                  >(prefix, `${prefix3}${PATH40}`, GET, option).json(),
                /**
                 * @returns 学校教員向け授業依頼一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_1n7b18l["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1n7b18l["get"]["resBody"],
                    BasicHeaders,
                    Methods_1n7b18l["get"]["status"]
                  >(prefix, `${prefix3}${PATH40}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_1n7b18l["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lessons: {
                _lessonId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH41}/${val5}`;

                  return {
                    /**
                     * @returns 学校教員向け授業詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_gyl7et["get"]["resBody"],
                        BasicHeaders,
                        Methods_gyl7et["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 学校教員向け授業詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_gyl7et["get"]["resBody"],
                        BasicHeaders,
                        Methods_gyl7et["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                no_rating: {
                  /**
                   * @returns 未評価の授業一覧
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_b080et["get"]["resBody"],
                      BasicHeaders,
                      Methods_b080et["get"]["status"]
                    >(prefix, `${prefix3}${PATH42}`, GET, option).json(),
                  /**
                   * @returns 未評価の授業一覧
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_b080et["get"]["resBody"],
                      BasicHeaders,
                      Methods_b080et["get"]["status"]
                    >(prefix, `${prefix3}${PATH42}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH42}`
                },
                /**
                 * @returns 学校教員向け授業一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_z03hnj["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_z03hnj["get"]["resBody"],
                    BasicHeaders,
                    Methods_z03hnj["get"]["status"]
                  >(prefix, `${prefix3}${PATH41}`, GET, option).json(),
                /**
                 * @returns 学校教員向け授業一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_z03hnj["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_z03hnj["get"]["resBody"],
                    BasicHeaders,
                    Methods_z03hnj["get"]["status"]
                  >(prefix, `${prefix3}${PATH41}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_z03hnj["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH41}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              school_announcements: {
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_18k9n0h["get"]["resBody"],
                    BasicHeaders,
                    Methods_18k9n0h["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option).json(),
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_18k9n0h["get"]["resBody"],
                    BasicHeaders,
                    Methods_18k9n0h["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH37}`
              },
              /**
               * @returns 学校教員情報取得
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_yra74p["get"]["resBody"],
                  BasicHeaders,
                  Methods_yra74p["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 学校教員情報取得
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_yra74p["get"]["resBody"],
                  BasicHeaders,
                  Methods_yra74p["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          login_school_teacher: {
            /**
             * @returns 学校教員ログイン情報
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_68vypb["get"]["resBody"],
                BasicHeaders,
                Methods_68vypb["get"]["status"]
              >(prefix, PATH43, GET, option).json(),
            /**
             * @returns 学校教員ログイン情報
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_68vypb["get"]["resBody"],
                BasicHeaders,
                Methods_68vypb["get"]["status"]
              >(prefix, PATH43, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH43}`
          }
        },
        teacher_auth: {
          password: {
            /**
             * @returns 複業先生パスワード変更
             */
            put: (option: {
              body: Methods_yapgnq["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["put"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["put"]["status"]
              >(prefix, PATH45, PUT, option).json(),
            /**
             * @returns 複業先生パスワード変更
             */
            $put: (option: {
              body: Methods_yapgnq["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["put"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["put"]["status"]
              >(prefix, PATH45, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns 複業先生パスワード変更リクエスト
             */
            post: (option: {
              body: Methods_yapgnq["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["post"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["post"]["status"]
              >(prefix, PATH45, POST, option).json(),
            /**
             * @returns 複業先生パスワード変更リクエスト
             */
            $post: (option: {
              body: Methods_yapgnq["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["post"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["post"]["status"]
              >(prefix, PATH45, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH45}`
          },
          sign_in: {
            /**
             * @returns 複業先生ログイン
             */
            post: (option: {
              body: Methods_7h4cis["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_7h4cis["post"]["resBody"],
                Methods_7h4cis["post"]["resHeaders"],
                Methods_7h4cis["post"]["status"]
              >(prefix, PATH46, POST, option).json(),
            /**
             * @returns 複業先生ログイン
             */
            $post: (option: {
              body: Methods_7h4cis["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_7h4cis["post"]["resBody"],
                Methods_7h4cis["post"]["resHeaders"],
                Methods_7h4cis["post"]["status"]
              >(prefix, PATH46, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH46}`
          },
          sign_out: {
            /**
             * @returns 複業先生ログアウト
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lzrqp["delete"]["resBody"],
                BasicHeaders,
                Methods_lzrqp["delete"]["status"]
              >(prefix, PATH47, DELETE, option).json(),
            /**
             * @returns 複業先生ログアウト
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lzrqp["delete"]["resBody"],
                BasicHeaders,
                Methods_lzrqp["delete"]["status"]
              >(prefix, PATH47, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH47}`
          },
          /**
           * @returns 複業先生認証系レスポンス
           */
          patch: (option: {
            body: Methods_1nfgpxm["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["patch"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["patch"]["status"]
            >(prefix, PATH44, PATCH, option).json(),
          /**
           * @returns 複業先生認証系レスポンス
           */
          $patch: (option: {
            body: Methods_1nfgpxm["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["patch"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["patch"]["status"]
            >(prefix, PATH44, PATCH, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 複業先生認証系レスポンス
           */
          post: (option: {
            body: Methods_1nfgpxm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["post"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["post"]["status"]
            >(prefix, PATH44, POST, option).json(),
          /**
           * @returns 複業先生認証系レスポンス
           */
          $post: (option: {
            body: Methods_1nfgpxm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["post"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["post"]["status"]
            >(prefix, PATH44, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH44}`
        },
        teacher_profiles: {
          _teacherProfileId: (val3: string) => {
            const prefix3 = `${PATH48}/${val3}`;

            return {
              edit: {
                /**
                 * @returns 複業先生プロフィール変更のデータ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b882u4["get"]["resBody"],
                    BasicHeaders,
                    Methods_1b882u4["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option).json(),
                /**
                 * @returns 複業先生プロフィール変更のデータ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b882u4["get"]["resBody"],
                    BasicHeaders,
                    Methods_1b882u4["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH34}`
              },
              judge_request: {
                /**
                 * @returns 複業先生再審査依頼
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b87kkx["post"]["resBody"],
                    BasicHeaders,
                    Methods_1b87kkx["post"]["status"]
                  >(prefix, `${prefix3}${PATH49}`, POST, option).json(),
                /**
                 * @returns 複業先生再審査依頼
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b87kkx["post"]["resBody"],
                    BasicHeaders,
                    Methods_1b87kkx["post"]["status"]
                  >(prefix, `${prefix3}${PATH49}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH49}`
              },
              /**
               * @returns 複業先生プロフィール更新
               */
              put: (option: {
                body: Methods_e1ycuv["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_e1ycuv["put"]["resBody"],
                  BasicHeaders,
                  Methods_e1ycuv["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 複業先生プロフィール更新
               */
              $put: (option: {
                body: Methods_e1ycuv["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_e1ycuv["put"]["resBody"],
                  BasicHeaders,
                  Methods_e1ycuv["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 複業先生プロフィール作成
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1ovggn7["get"]["resBody"],
                BasicHeaders,
                Methods_1ovggn7["get"]["status"]
              >(prefix, PATH50, GET, option).json(),
            /**
             * @returns 複業先生プロフィール作成
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1ovggn7["get"]["resBody"],
                BasicHeaders,
                Methods_1ovggn7["get"]["status"]
              >(prefix, PATH50, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH50}`
          },
          /**
           * @returns 複業先生プロフィール登録
           */
          post: (option: {
            body: Methods_3ia6mm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3ia6mm["post"]["resBody"],
              BasicHeaders,
              Methods_3ia6mm["post"]["status"]
            >(prefix, PATH48, POST, option).json(),
          /**
           * @returns 複業先生プロフィール登録
           */
          $post: (option: {
            body: Methods_3ia6mm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3ia6mm["post"]["resBody"],
              BasicHeaders,
              Methods_3ia6mm["post"]["status"]
            >(prefix, PATH48, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH48}`
        },
        teachers: {
          _teacherId_number: (val3: number) => {
            const prefix3 = `${PATH51}/${val3}`;

            return {
              teacher_announcements: {
                _teacherAnnouncementId: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH52}/${val5}`;

                  return {
                    announcement_check: {
                      /**
                       * @returns OK
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<
                          Methods_1336rzc["post"]["resBody"],
                          BasicHeaders,
                          Methods_1336rzc["post"]["status"]
                        >(prefix, `${prefix5}${PATH38}`, POST, option).json(),
                      /**
                       * @returns OK
                       */
                      $post: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_1336rzc["post"]["resBody"],
                          BasicHeaders,
                          Methods_1336rzc["post"]["status"]
                        >(prefix, `${prefix5}${PATH38}`, POST, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH38}`
                    }
                  };
                },
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1ico1hg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1ico1hg["get"]["status"]
                  >(prefix, `${prefix3}${PATH52}`, GET, option).json(),
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1ico1hg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1ico1hg["get"]["status"]
                  >(prefix, `${prefix3}${PATH52}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH52}`
              }
            };
          },
          _teacherId_string: (val3: string) => {
            const prefix3 = `${PATH51}/${val3}`;

            return {
              e_learnings: {
                new: {
                  /**
                   * @returns eラーニング受験
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1hk2anv["get"]["resBody"],
                      BasicHeaders,
                      Methods_1hk2anv["get"]["status"]
                    >(prefix, `${prefix3}${PATH54}`, GET, option).json(),
                  /**
                   * @returns eラーニング受験
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1hk2anv["get"]["resBody"],
                      BasicHeaders,
                      Methods_1hk2anv["get"]["status"]
                    >(prefix, `${prefix3}${PATH54}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH54}`
                },
                /**
                 * @returns eラーニング回答
                 */
                post: (option: {
                  body: Methods_gtxoyu["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_gtxoyu["post"]["resBody"],
                    BasicHeaders,
                    Methods_gtxoyu["post"]["status"]
                  >(prefix, `${prefix3}${PATH53}`, POST, option).json(),
                /**
                 * @returns eラーニング回答
                 */
                $post: (option: {
                  body: Methods_gtxoyu["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_gtxoyu["post"]["resBody"],
                    BasicHeaders,
                    Methods_gtxoyu["post"]["status"]
                  >(prefix, `${prefix3}${PATH53}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH53}`
              },
              lesson_recruitments: {
                /**
                 * @returns 複業先生応募授業
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_7lhx1f["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_7lhx1f["get"]["resBody"],
                    BasicHeaders,
                    Methods_7lhx1f["get"]["status"]
                  >(prefix, `${prefix3}${PATH39}`, GET, option).json(),
                /**
                 * @returns 複業先生応募授業
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_7lhx1f["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_7lhx1f["get"]["resBody"],
                    BasicHeaders,
                    Methods_7lhx1f["get"]["status"]
                  >(prefix, `${prefix3}${PATH39}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_7lhx1f["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH39}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lesson_requests: {
                _lessonRequestId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH40}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生向け授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_123jsc1["get"]["resBody"],
                        BasicHeaders,
                        Methods_123jsc1["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 複業先生向け授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_123jsc1["get"]["resBody"],
                        BasicHeaders,
                        Methods_123jsc1["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生向け授業依頼一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_1gehdre["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1gehdre["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gehdre["get"]["status"]
                  >(prefix, `${prefix3}${PATH40}`, GET, option).json(),
                /**
                 * @returns 複業先生向け授業依頼一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_1gehdre["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1gehdre["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gehdre["get"]["status"]
                  >(prefix, `${prefix3}${PATH40}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_1gehdre["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lessons: {
                _lessonId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH41}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生向け授業詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_dibnew["get"]["resBody"],
                        BasicHeaders,
                        Methods_dibnew["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 複業先生向け授業詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_dibnew["get"]["resBody"],
                        BasicHeaders,
                        Methods_dibnew["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生向け授業一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_1p36wu8["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1p36wu8["get"]["resBody"],
                    BasicHeaders,
                    Methods_1p36wu8["get"]["status"]
                  >(prefix, `${prefix3}${PATH41}`, GET, option).json(),
                /**
                 * @returns 複業先生向け授業一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_1p36wu8["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1p36wu8["get"]["resBody"],
                    BasicHeaders,
                    Methods_1p36wu8["get"]["status"]
                  >(prefix, `${prefix3}${PATH41}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_1p36wu8["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH41}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              teacher_bank_accounts: {
                _teacherBankAccountId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH55}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生銀行口座削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1syykh8["delete"]["resBody"],
                        BasicHeaders,
                        Methods_1syykh8["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns 複業先生銀行口座削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_1syykh8["delete"]["resBody"],
                        BasicHeaders,
                        Methods_1syykh8["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生銀行口座取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_3u3trf["get"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["get"]["status"]
                  >(prefix, `${prefix3}${PATH55}`, GET, option).json(),
                /**
                 * @returns 複業先生銀行口座取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_3u3trf["get"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["get"]["status"]
                  >(prefix, `${prefix3}${PATH55}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 複業先生銀行口座作成
                 */
                post: (option: {
                  body: Methods_3u3trf["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_3u3trf["post"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["post"]["status"]
                  >(prefix, `${prefix3}${PATH55}`, POST, option).json(),
                /**
                 * @returns 複業先生銀行口座作成
                 */
                $post: (option: {
                  body: Methods_3u3trf["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_3u3trf["post"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["post"]["status"]
                  >(prefix, `${prefix3}${PATH55}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH55}`
              },
              teacher_identifications: {
                /**
                 * @returns 複業先生本人確認開始
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_e0322r["post"]["resBody"],
                    BasicHeaders,
                    Methods_e0322r["post"]["status"]
                  >(prefix, `${prefix3}${PATH56}`, POST, option).json(),
                /**
                 * @returns 複業先生本人確認開始
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_e0322r["post"]["resBody"],
                    BasicHeaders,
                    Methods_e0322r["post"]["status"]
                  >(prefix, `${prefix3}${PATH56}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH56}`
              },
              teacher_videos: {
                _teacherVideoId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH57}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生自己紹介動画削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_108k0v3["delete"]["resBody"],
                        BasicHeaders,
                        Methods_108k0v3["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns 複業先生自己紹介動画削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_108k0v3["delete"]["resBody"],
                        BasicHeaders,
                        Methods_108k0v3["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生自己紹介動画作成
                 */
                post: (option: {
                  body: Methods_1l9446c["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1l9446c["post"]["resBody"],
                    BasicHeaders,
                    Methods_1l9446c["post"]["status"]
                  >(
                    prefix,
                    `${prefix3}${PATH57}`,
                    POST,
                    option,
                    "FormData"
                  ).json(),
                /**
                 * @returns 複業先生自己紹介動画作成
                 */
                $post: (option: {
                  body: Methods_1l9446c["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1l9446c["post"]["resBody"],
                    BasicHeaders,
                    Methods_1l9446c["post"]["status"]
                  >(prefix, `${prefix3}${PATH57}`, POST, option, "FormData")
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH57}`
              },
              /**
               * @returns 複業先生詳細
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_2uegaq["get"]["resBody"],
                  BasicHeaders,
                  Methods_2uegaq["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 複業先生詳細
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_2uegaq["get"]["resBody"],
                  BasicHeaders,
                  Methods_2uegaq["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          login_teacher: {
            /**
             * @returns 複業先生ログイン情報
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lpu3cj["get"]["resBody"],
                BasicHeaders,
                Methods_lpu3cj["get"]["status"]
              >(prefix, PATH58, GET, option).json(),
            /**
             * @returns 複業先生ログイン情報
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lpu3cj["get"]["resBody"],
                BasicHeaders,
                Methods_lpu3cj["get"]["status"]
              >(prefix, PATH58, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH58}`
          },
          lp_classi: {
            /**
             * @returns classi向けLP
             */
            get: (option: {
              query: Methods_1t202l5["get"]["query"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1t202l5["get"]["resBody"],
                BasicHeaders,
                Methods_1t202l5["get"]["status"]
              >(prefix, PATH59, GET, option).json(),
            /**
             * @returns classi向けLP
             */
            $get: (option: {
              query: Methods_1t202l5["get"]["query"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1t202l5["get"]["resBody"],
                BasicHeaders,
                Methods_1t202l5["get"]["status"]
              >(prefix, PATH59, GET, option)
                .json()
                .then((r) => r.body),
            $path: (
              option?:
                | {
                    method?: "get" | undefined;
                    query: Methods_1t202l5["get"]["query"];
                  }
                | undefined
            ) =>
              `${prefix}${PATH59}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
          },
          /**
           * 複業先生一覧取得
           * @returns 複業先生一覧
           */
          get: (
            option?:
              | {
                  query?: Methods_jvfwzs["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jvfwzs["get"]["resBody"],
              BasicHeaders,
              Methods_jvfwzs["get"]["status"]
            >(prefix, PATH51, GET, option).json(),
          /**
           * 複業先生一覧取得
           * @returns 複業先生一覧
           */
          $get: (
            option?:
              | {
                  query?: Methods_jvfwzs["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jvfwzs["get"]["resBody"],
              BasicHeaders,
              Methods_jvfwzs["get"]["status"]
            >(prefix, PATH51, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_jvfwzs["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH51}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        teaching_plan_ideas: {
          new: {
            /**
             * @returns 授業内容提案回答データ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1on40ck["get"]["resBody"],
                BasicHeaders,
                Methods_1on40ck["get"]["status"]
              >(prefix, PATH61, GET, option).json(),
            /**
             * @returns 授業内容提案回答データ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1on40ck["get"]["resBody"],
                BasicHeaders,
                Methods_1on40ck["get"]["status"]
              >(prefix, PATH61, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH61}`
          },
          /**
           * @returns 授業内容提案
           */
          post: (option: {
            body: Methods_16ra1rl["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_16ra1rl["post"]["resBody"],
              BasicHeaders,
              Methods_16ra1rl["post"]["status"]
            >(prefix, PATH60, POST, option).json(),
          /**
           * @returns 授業内容提案
           */
          $post: (option: {
            body: Methods_16ra1rl["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_16ra1rl["post"]["resBody"],
              BasicHeaders,
              Methods_16ra1rl["post"]["status"]
            >(prefix, PATH60, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH60}`
        },
        tools: {
          teacher_common_data: {
            /**
             * @returns 全ページ共通データ取得API
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_b7n32a["get"]["resBody"],
                BasicHeaders,
                Methods_b7n32a["get"]["status"]
              >(prefix, PATH62, GET, option).json(),
            /**
             * @returns 全ページ共通データ取得API
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_b7n32a["get"]["resBody"],
                BasicHeaders,
                Methods_b7n32a["get"]["status"]
              >(prefix, PATH62, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH62}`
          }
        },
        webhooks: {
          trustdock: {
            post: (option: {
              body: Methods_5vgulz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_5vgulz["post"]["status"]>(
                prefix,
                PATH63,
                POST,
                option
              ).send(),
            $post: (option: {
              body: Methods_5vgulz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_5vgulz["post"]["status"]>(
                prefix,
                PATH63,
                POST,
                option
              )
                .send()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH63}`
          }
        }
      }
    }
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
