import { useAlertMessages } from "hooks/useAlertMessages";

import {
  ContactFormKeysDevelopment,
  ContactCorsProxyLocal,
  ContactFormKeysProduction,
  ContactCorsProxyNetwork
} from "constants/ContactFormKeys";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import contactFormSchema, {
  ContactFormType
} from "../constants/contactFormSchema";

const useContactPageApi = () => {
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();

  const form = useForm({
    resolver: yupResolver(contactFormSchema),
    mode: "all"
  });

  const ContactFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? ContactFormKeysProduction
      : ContactFormKeysDevelopment;
  const ContactCorsProxy =
    process.env.REACT_APP_BUILD_ENV === "development"
      ? ContactCorsProxyLocal
      : ContactCorsProxyNetwork;

  const submitContact = (data: ContactFormType) => {
    const submitParams = new FormData();
    submitParams.append(ContactFormKeys.name, data.name);
    submitParams.append(ContactFormKeys.nameKana, data.kana_name);
    submitParams.append(ContactFormKeys.email, data.email);
    submitParams.append(ContactFormKeys.contactType, data.contactType);
    submitParams.append(ContactFormKeys.contactContents, data.contactContents);
    Axios.post(ContactCorsProxy + ContactFormKeys.action, submitParams)
      .then((response) => {
        console.log(response);
        addSuccessMessage("お問い合わせを受け付けました。");
      })
      .catch((error) => {
        console.log(error.response);
        addErrorMessage("お問い合わせの送信に失敗しました");
      });
  };

  return { submitContact, form };
};

export default useContactPageApi;
