import Divider from "@mui/material/Divider";
import { useAtomValue } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";

import { currentUserAtom } from "store/AuthStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  detailsAtom as schoolDetailsAtom,
  teacherAtom,
  supporterAtom as schoolSupporterAtom,
  lessonRequestCommentsAtom as schoolLessonRequestCommentsAtom
} from "features/schools/store/lessonsStore";
import {
  detailsAtom as teacherDetailsAtom,
  schoolTeacherAtom,
  supporterAtom as teacherSupporterAtom,
  lessonRequestCommentsAtom as teacherLessonRequestCommentsAtom
} from "features/teachers/mypage/lessons/id/store";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import Button from "components/atoms/mui/Button";
import { ExpandMore } from "@mui/icons-material";
import ModalTransition from "components/organisms/ModalTransition";
import MessageBlock from "components/molecules/lessons/MessageBlock";
import MessageField from "components/molecules/lessons/MessageField";

type ChatProps = {
  postRequestComments: (data: Methods["post"]["reqBody"]) => void;
  deleteRequestComments: (commentId: string) => void;
  variant?: "teacher" | "school";
};

const Chat: React.FC<ChatProps> = (props) => {
  const { postRequestComments, deleteRequestComments, variant } = props;

  const theme = useTheme();

  const currentUser = useAtomValue(currentUserAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);
  const details =
    variant === "teacher"
      ? useAtomValue(teacherDetailsAtom)
      : useAtomValue(schoolDetailsAtom);
  const teacher = useAtomValue(teacherAtom);
  const schoolTeacher = useAtomValue(schoolTeacherAtom);
  const supporter =
    variant === "teacher"
      ? useAtomValue(teacherSupporterAtom)
      : useAtomValue(schoolSupporterAtom);
  const LessonRequestComments =
    variant === "teacher"
      ? useAtomValue(teacherLessonRequestCommentsAtom)
      : useAtomValue(schoolLessonRequestCommentsAtom);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isOpen, setIsOpen] = useState(false);

  const isChatActive = details?.lesson_completed_at
    ? dayjs().diff(dayjs(details.lesson_completed_at), "day") <= 7
    : true;

  // 最下部までスクロール
  useEffect(() => {
    // スクロール位置を最下部に設定
    const scrollToBottom = () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth"
        });
      }
    };

    // PDFのプレビューに時間がかかるので、0.3秒待ってからスクロール
    const timeoutId = setTimeout(scrollToBottom, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [LessonRequestComments, isOpen]);

  const ChatContent = (
    <Box
      component="section"
      ref={chatContainerRef}
      sx={{
        padding: "0 1.5rem",
        background: theme.palette.common.white,
        border: isMobile ? 0 : `1px solid ${theme.palette.border?.main}`,
        borderRadius: "4px",
        maxHeight: isMobile ? "calc(100vh - 80px)" : "calc(100vh - 300px)",
        overflowY: "auto"
      }}
    >
      <MessageBlock
        src=""
        name={`LX事務局−${supporter?.name}`}
        date={dayjs(details?.accept_at).format("YYYY/MM/DD HH:mm")}
        message={`お世話になっております。LX事務局です。
この度はご縁に感謝しております。
今後、授業に関するやりとりは当チャットにてよろしくお願いいたします。
授業日の日時をお間違いないか、今一度ご確認をお願いいたします。
${
  details?.lesson_options.some((option) => option.id === 3)
    ? `授業の前に打ち合わせを行なっていただくため、事務局のからの連絡をお待ちください。`
    : `また、授業詳細を話し合うためのお打ち合わせの日時を確定していただきたく存じます。
お打ち合わせ日は授業日から２週間程前を目安にお願いいたします。
当チャット内にて学校様、複業先生様で日程調整、打ち合わせのURL発行をお願いいたします。`
}
授業終了まで、どうぞよろしくお願いいたします。`}
      />
      <Divider />
      {details?.is_recruitment === true ? (
        <MessageBlock
          src={schoolTeacher?.image.url || currentSchool.image.url}
          name={schoolTeacher?.name || currentSchool.name}
          date={dayjs(details?.accept_at).format("YYYY/MM/DD HH:mm")}
          message={details.reply_comment}
          deleteRequestComments={deleteRequestComments}
        />
      ) : (
        <MessageBlock
          src={teacher?.image.url || currentUser.image.url}
          name={teacher?.name || currentUser.name}
          date={dayjs(details?.accept_at).format("YYYY/MM/DD HH:mm")}
          message={details?.reply_comment || ""}
          deleteRequestComments={deleteRequestComments}
        />
      )}
      <Divider />
      {LessonRequestComments?.lesson_request_comments.map((chat, index) => (
        <React.Fragment key={index}>
          <MessageBlock
            messageId={chat.id}
            sendUserId={chat.send_user.id}
            src={chat.send_user.image.url}
            name={
              chat.send_user.category === "office_user"
                ? `LX事務局−${chat.send_user.name}`
                : chat.send_user.name
            }
            date={dayjs(chat.updated_at).format("YYYY/MM/DD HH:mm")}
            message={chat.comment}
            isDeleted={chat.is_deleted}
            isOfficeNotification={chat.is_office_notification}
            deleteRequestComments={deleteRequestComments}
            file={chat.file}
          />
          <Divider />
        </React.Fragment>
      ))}

      {isChatActive && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            background: theme.palette.common.white,
            zIndex: 100
          }}
        >
          <Divider />
          <MessageField postRequestComments={postRequestComments} />
        </Box>
      )}
    </Box>
  );

  if (isMobile) {
    return (
      <>
        {!isOpen && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: "8px",
              zIndex: 100
            }}
          >
            <Button
              onClick={() => setIsOpen(true)}
              endIcon={<ExpandMore />}
              sx={{
                background: theme.palette.secondary.dark,
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
                "&:active": {
                  backgroundColor: theme.palette.secondary.main
                }
              }}
            >
              メッセージ
            </Button>
          </Box>
        )}
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          TransitionComponent={ModalTransition}
          fullWidth
          {...props}
          sx={{
            "& .MuiPaper-root": {
              position: "fixed",
              width: "100%",
              margin: 0,
              bottom: 0,
              borderTopLeftRadius: { xs: "16px", sm: "4px" },
              borderTopRightRadius: { xs: "16px", sm: "4px" }
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            onClick={() => setIsOpen(false)}
            px={3}
            py={1}
          >
            <Typography
              sx={{
                color: theme.palette.common.white,
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              メッセージ
            </Typography>
            <ExpandMore sx={{ color: theme.palette.common.white }} />
          </Box>
          {ChatContent}
        </Dialog>
      </>
    );
  }

  return ChatContent;
};

export default Chat;
