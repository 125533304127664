import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import CommonLoading from "components/atoms/CommonLoading";
import RecruitmentListItem from "features/recruitments/index/molecules/RecruitmentListItem";
import RecruitmentListItemForGuest from "features/recruitments/index/molecules/RecruitmentListItemForGuest";
import RecruitmentsCount from "features/recruitments/index/molecules/RecruitmentsCount";
import ToRegisterCover from "features/recruitments/index/molecules/ToRegisterCover";
import { isLoggedInAtom } from "store/AuthStore";
import type React from "react";
import { commonRecruitmentsAtom } from "../store";

interface RecruitmentListProps {
  isLoading: boolean;
}

const RecruitmentList: React.FC<RecruitmentListProps> = ({ isLoading }) => {
  const commonRecruitments = useAtomValue(commonRecruitmentsAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  return (
    <Box
      component="section"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        height: "100%"
      }}
    >
      <RecruitmentsCount />
      {!isLoading ? (
        !commonRecruitments?.data || commonRecruitments?.data.length === 0 ? (
          <Typography>現在募集中の授業はありません</Typography>
        ) : (
          commonRecruitments?.data.map((recruitment, index) => {
            if (!recruitment.details || !recruitment.school_teacher)
              return null;
            if (isLoggedIn) {
              return (
                <RecruitmentListItem
                  key={index}
                  id={recruitment.details.id}
                  title={recruitment.details.title}
                  schoolName={recruitment.school_teacher.school_name}
                  isOnline={recruitment.details.is_online}
                  tagName={recruitment.details.tag.name}
                  schoolAddress={
                    recruitment.school_teacher.school_prefecture_name
                  }
                  recruitmentDeadAt={recruitment.details.recruitment_dead_at}
                  schoolGradeName={recruitment.details.school_grade_name}
                  schoolMinutes={recruitment.details.school_minutes}
                  studentNumber={recruitment.details.student_number}
                  updatedAt={recruitment.details.updated_at}
                  proposedDates={recruitment.details.proposed_dates.data || []}
                  step={recruitment.details.step}
                />
              );
            }
            return (
              <RecruitmentListItemForGuest
                key={index}
                id={recruitment.details.id}
                title={recruitment.details.title}
                isOnline={recruitment.details.is_online}
                tagName={recruitment.details.tag.name}
                schoolPrefectureName={
                  recruitment.school_teacher.school_prefecture_name
                }
                schoolTypeName={recruitment.school_teacher.school_type_name}
                schoolDivisionName={
                  recruitment.school_teacher.school_division_name
                }
                recruitmentDeadAt={recruitment.details.recruitment_dead_at}
                schoolGradeName={recruitment.details.school_grade_name}
                schoolMinutes={recruitment.details.school_minutes}
                studentNumber={recruitment.details.student_number}
                updatedAt={recruitment.details.updated_at}
                proposedDates={recruitment.details.proposed_dates.data || []}
                step={recruitment.details.step}
              />
            );
          })
        )
      ) : (
        <CommonLoading />
      )}
      {!isLoggedIn && !isLoading && commonRecruitments?.data?.length && (
        <ToRegisterCover />
      )}
    </Box>
  );
};

export default RecruitmentList;
