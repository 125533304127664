import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/index";

type TeacherListType = Methods["get"]["resBody"]["data"];
type keywordsType = Methods["get"]["resBody"]["keywords"];

export type SortType = "recent" | "total_rating" | "lesson_counts";

export const teachersDataAtom = atom<TeacherListType>([]);

export const tagIdsAtom = atom<number[]>([]);
export const prefectureIdAtom = atom(0);
export const searchTextAtom = atom("");
export const searchFormerSchoolAtom = atom("");

export const pageAtom = atom(1);
export const keywordsAtom = atom<keywordsType>(undefined);

export const conditionsResetAtom = atom(null, (_get, set) => {
  set(tagIdsAtom, []);
  set(prefectureIdAtom, 0);
  set(searchTextAtom, "");
  set(searchFormerSchoolAtom, "");
  set(pageAtom, 1);
});
