import React from "react";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import Loading from "components/atoms/Loading";

import useQueryParams from "hooks/useQueryParams";
import useQuestionnairesApi from "./useQuestionnairesApi";

import LessonInfoBlock from "./organisms/LessonInfoBlock";
import BeforeQuestionnairesForm from "./organisms/BeforeQuestionnairesForm";

type paramsTypes = {
  id: string;
};

type QueryParamsType = {
  unique_id?: string;
};

const Before: React.FC = () => {
  const { id } = useParams<paramsTypes>();
  const { getQueryParams } = useQueryParams<QueryParamsType>();

  const { unique_id } = getQueryParams(["unique_id"]);

  const { isLoading, submitBeforeQuestionnaireForm } = useQuestionnairesApi(
    id,
    unique_id || ""
  );

  return (
    <QuestionnairesLayout title="授業前アンケート">
      <Meta
        title="複業先生 | 授業前アンケート"
        description="この後の授業についてのアンケートです。"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <LessonInfoBlock />
          <BeforeQuestionnairesForm
            submitForm={submitBeforeQuestionnaireForm}
          />
        </Container>
      )}
    </QuestionnairesLayout>
  );
};

export default Before;
