import useAspidaSWR from "@aspida/swr";
import { lpClassiFrontData } from "constants/lpClassiFrontData";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { exampleTeachersAtom } from "store/LpClassiStore";

const useLPClassiApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const [, setExampleTeachers] = useAtom(exampleTeachersAtom);
  const [, setCookie] = useCookies(["classi_key"]);

  const ids = lpClassiFrontData.map((data) => data.id);

  useAspidaSWR(aspida(headers).api.v1.teachers.lp_classi, {
    query: { teacher_ids: ids },
    onError: () => {
      addErrorMessage("授業情報の取得に失敗しました");
    },
    onSuccess: (response) => {
      setExampleTeachers(response.data);
    }
  });

  useEffect(() => {
    setCookie("classi_key", "f5XUpRqV");
  });
};

export default useLPClassiApi;
