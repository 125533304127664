import { useAtomValue } from "jotai";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import LessonOptionItem from "components/atoms/LessonOptionItem";

// MEMO: moleculesだが複業先生情報の表示を機能として持つため、例外的にatomと紐付けて再利用
import { teacherAtom, detailsAtom } from "features/schools/store/requestsStore";

import type React from "react";

const TeacherInfoBlock: React.FC = () => {
  const theme = useTheme();

  const details = useAtomValue(detailsAtom);
  const teacher = useAtomValue(teacherAtom);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: ".5rem 1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <div>
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          [依頼した複業先生]
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={teacher?.image.url}
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              marginRight: "1rem"
            }}
          />
          <div>
            <Typography
              sx={{ fontSize: ".8rem", color: theme.palette.sub.main }}
            >
              {teacher?.organization_name}
            </Typography>
            <Typography fontWeight="bold">{teacher?.name}</Typography>
          </div>
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".25rem"
        }}
      >
        <Typography>サポートオプション：</Typography>
        {details && details.lesson_options.length > 0 ? (
          <>
            {details.lesson_options.map((lessonOption) => (
              <LessonOptionItem name={lessonOption.name} />
            ))}
          </>
        ) : (
          <Typography>なし</Typography>
        )}
      </Box>
      <div>
        <Typography>授業テーマ：</Typography>
        <Chip
          label={details?.tag}
          variant="outlined"
          color="default"
          sx={{
            height: "32px",
            borderRadius: "16px",
            margin: ".5rem auto",
            backgroundColor: theme.palette.backgroundColor?.main,
            borderColor: theme.palette.muted?.main,
            color: theme.palette.sub.main
          }}
        />
      </div>
    </Box>
  );
};

export default TeacherInfoBlock;
