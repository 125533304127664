import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormHelperText, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Add from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Button from "components/atoms/mui/Button";
import FormDatetimeRange from "components/molecules/FormDatetimeRange";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormLabel from "components/atoms/FormLabel";
import FormRadioGroup from "components/molecules/FormRadioGroup";

import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes";
import { postLessonMeetingPossibleDatimesFormSchema } from "features/schools/constants/lessons/postLessonMeetingPossibleDatimesFormSchema";
import { postLessonMeetingPossibleDatimesFormDefaultValues } from "features/schools/constants/lessons/postLessonMeetingPossibleDatimesFormDefaultValues";

import dayjs from "dayjs";
import { meetingScheduleHoursOptions } from "constants/lessons/meetingScheduleSelectOptions";
import type { MeetingScheduleStepType } from "../../organisms/MeetingScheduleBlock";

type postLessonMeetingPossibleDatimesType = Methods["post"]["reqBody"];

type SuggestedDateFormProps = {
  postLessonMeetingPossibleDatimes: (
    data: postLessonMeetingPossibleDatimesType
  ) => void;
  setStep: React.Dispatch<React.SetStateAction<MeetingScheduleStepType>>;
};

const SuggestedDateForm: React.FC<SuggestedDateFormProps> = (props) => {
  const { postLessonMeetingPossibleDatimes, setStep } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const methods = useForm<postLessonMeetingPossibleDatimesType>({
    mode: "onChange",
    resolver: yupResolver(postLessonMeetingPossibleDatimesFormSchema),
    defaultValues: postLessonMeetingPossibleDatimesFormDefaultValues
  });

  const { fields, append, remove } = useFieldArray({
    name: "lesson_meeting_possible_datetimes",
    control: methods.control,
    rules: {
      required: true,
      maxLength: 3
    }
  });

  // FIXME: エラーメッセージが更新されない
  const errorMessage =
    methods.formState.errors.lesson_meeting_possible_datetimes?.message;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
          onSubmit={methods.handleSubmit(postLessonMeetingPossibleDatimes)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography variant="h2">
              複業先生との打ち合わせを行いましょう
            </Typography>
            <Typography>
              打ち合わせ日時の候補を追加してください。
              追加いただいた候補日時の中から複業先生に1時間の枠を選択いただきます。授業予定の2週間前には打ち合わせを終えられるスケジュールにし、1時間以上の時間が取れる日を追加しましょう。
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: ".5rem"
              }}
            >
              <FormLabel labelName="打ち合わせ候補日を選択" isRequired />
              {fields.map((field, index) => (
                <Box
                  key={field.id}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: ".5rem",
                    alignItems: isMobile ? "flex-start" : "center"
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ width: "5rem" }}
                  >
                    {`候補日${index + 1}`}
                  </Typography>
                  <FormDatetimeRange
                    {...field}
                    key={field.id}
                    hoursOptions={meetingScheduleHoursOptions}
                    nameFrom={`lesson_meeting_possible_datetimes[${index}].possible_datetime_from`}
                    nameTo={`lesson_meeting_possible_datetimes[${index}].possible_datetime_to`}
                    minDate={dayjs().add(3, "day").toDate()}
                    monitoredTargets={["lesson_meeting_possible_datetimes"]}
                  />
                  {index > 0 ? (
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => remove(index)}
                      sx={{
                        width: "6rem",
                        padding: ".5rem"
                      }}
                      startIcon={<DeleteForeverIcon />}
                    >
                      削除
                    </Button>
                  ) : (
                    <Box
                      sx={{
                        width: "6rem",
                        padding: ".5rem",
                        display: isMobile ? "none" : "block"
                      }}
                    />
                  )}
                </Box>
              ))}
              <Box>
                <Button
                  isLink
                  size="small"
                  startIcon={
                    <Add
                      sx={{
                        color: theme.palette.secondary?.main,
                        height: "1rem",
                        width: "1rem"
                      }}
                    />
                  }
                  onClick={() => {
                    append(
                      postLessonMeetingPossibleDatimesFormDefaultValues
                        .lesson_meeting_possible_datetimes[0]
                    );
                  }}
                >
                  候補日を追加する
                </Button>
              </Box>
              {errorMessage && (
                <FormHelperText sx={{ color: theme.palette.error.main }}>
                  {errorMessage}
                </FormHelperText>
              )}
            </Box>
            <FormRadioGroup
              name="is_online"
              label="実施方法"
              supplementalText="基本的に打ち合わせはオンライン（1時間）を想定しています。対面の場合は交通費の取り扱いなどを調整してください。"
              options={[
                { value: true, label: "オンライン" },
                { value: false, label: "対面" }
              ]}
              isRequired
              raidoBackgroundColor={theme.palette.common.white}
            />
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "flex-end"
              }}
            >
              {/* // MEMO: 背景がprimayBackgroudの場合の指定の検討が必要 */}
              <Button
                variant="outlined"
                onClick={() => setStep("selectWay")}
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#f5f5f5"
                  }
                }}
              >
                戻る
              </Button>
              <FormSubmitButton
                label="候補日を送信"
                disabled={!methods.formState.isValid}
              />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default SuggestedDateForm;
