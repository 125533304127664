import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import LessonCompleteLabel from "features/schools/components/atoms/mypage/v2/home/LessonCompleteLabel";
import LessonFinishLabel from "features/schools/components/atoms/mypage/v2/home/LessonFinishLabel";
import LessonPreparationLabel from "features/schools/components/atoms/mypage/v2/home/LessonPreparationLabel";

import type React from "react";
import { formatDateTimeStringWithDay } from "utils/date";
import LessonTeacherData from "./LessonTeacherData";

type LessonItemProps = {
  id: number;
  exactStartAt: Date;
  schoolMinutes: number;
  step: string;
  teacherName: string;
  teacherImage: string;
  teacherRank: string;
};

const LessonItem: React.FC<LessonItemProps> = (props) => {
  const {
    id,
    exactStartAt,
    schoolMinutes,
    step,
    teacherName,
    teacherImage,
    teacherRank
  } = props;
  const formatJapaneseDaytime = formatDateTimeStringWithDay(exactStartAt);

  const getStatusLabel = () => {
    switch (step) {
      case "suggest_meeting_date":
      case "select_meeting_date":
      case "adjust_meeting_place":
      case "before_meeting":
      case "preparation":
        return <LessonPreparationLabel />;
      case "lesson_end":
        return <LessonFinishLabel />;
      case "completed":
        return <LessonCompleteLabel />;
      default:
        return null;
    }
  };

  return (
    <Link
      href={`/schools/mypage/lessons/${id}`}
      sx={{
        width: "100%",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">
            {formatJapaneseDaytime}（{schoolMinutes}分間）
          </Typography>
          <LessonTeacherData
            teacherImage={teacherImage}
            teacherName={teacherName}
            teacherRank={teacherRank}
          />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {getStatusLabel()}
        </Box>
      </Box>
    </Link>
  );
};

export default LessonItem;
