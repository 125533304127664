import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";

import { Methods } from "api/api/v1/teachers/index";
import {
  prefecturesData,
  organizationCategoriesData,
  paginationData
} from "store/MasterData";

import useQueryParams from "hooks/useQueryParams";
import useHeaders from "hooks/useHeaders";
import { teachersDataAtom, keywordsAtom } from "./store";

export type getIndexType = Methods["get"]["query"];

// MEMO: Excludeを利用してundefinedを除外。受付はともかく、このpramsは存在すると思われるため。
type CleanGetIndexType = Exclude<getIndexType, undefined>;

const useIndexPageApi = () => {
  const { getQueryParams, setQueryParams } =
    useQueryParams<CleanGetIndexType>();
  const headers = useHeaders();
  const queryParams = getQueryParams([
    "text",
    "former_school",
    "tag",
    "prefecture_id",
    "pages",
    "sort"
  ]);

  const [, setPrefectures] = useAtom(prefecturesData);
  const [, setOrganizationCategories] = useAtom(organizationCategoriesData);
  const [, setPagination] = useAtom(paginationData);
  const [, setTeachersData] = useAtom(teachersDataAtom);
  const [, setKeywords] = useAtom(keywordsAtom);

  const methods = useForm({
    mode: "onChange",
    defaultValues: { ...queryParams, pages: 1 } as getIndexType
  });

  const { error, isLoading } = useAspidaSWR(aspida(headers).api.v1.teachers, {
    query: queryParams,
    onSuccess: (data) => {
      setTeachersData(data?.data || []);
      setKeywords(data?.keywords);
      setPrefectures(data?.prefectures || []);
      setOrganizationCategories(data?.tags || []);
      setPagination(data?.pagination);
    }
  });

  const search = (searchQuery: getIndexType) => {
    setQueryParams({
      ...queryParams,
      ...searchQuery,
      // MEMO: searchQueryのSortがundifinedで更新されることを防ぐ
      sort: queryParams.sort,
      pages: 1
    });
  };

  const reset = () => {
    methods.reset({
      text: "",
      former_school: "",
      pages: 1
    });
    setQueryParams({
      text: "",
      former_school: "",
      pages: 1,
      sort: queryParams.sort
    });
  };

  return {
    error,
    search,
    reset,
    isLoading,
    queryParams,
    methods
  };
};

export default useIndexPageApi;
