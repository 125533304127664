import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { useEffect } from "react";

import Meta from "components/organisms/Meta";
import SignupForm from "features/root/components/organisms/defaultSignup/SignupForm";
import SelectAccountSection from "features/root/components/organisms/defaultSignup/SelectAccountSection";
import useQueryParams from "hooks/useQueryParams";
import { activeStateAtom } from "store/DefaultSignupStore";

import type React from "react";
import useTeacherAuthApi from "hooks/useTeacherAuthApi";
import useSchoolAuthApi from "hooks/useSchoolAuthApi";
import { FormProvider } from "react-hook-form";
import AuthLayout from "components/layouts/AuthLayout";

type QueryParamsType = {
  active?: "" | "teacher" | "school";
};

const Signup: React.FC = () => {
  const { getQueryParams } = useQueryParams<QueryParamsType>();
  const [activeState, setActiveState] = useAtom(activeStateAtom);
  const { signup: teacherSignup, signupForm: teacherSignupForm } =
    useTeacherAuthApi();
  const { signup: schoolSignup, signupForm: schoolSignupForm } =
    useSchoolAuthApi();

  useEffect(() => {
    const { active } = getQueryParams(["active"]);
    setActiveState(active || "");
  }, [setActiveState]);

  return (
    <AuthLayout>
      <Meta
        title="複業先生|新規登録"
        description="複業先生・教員の方向けのアカウント作成ページです。複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。"
      />
      <Box
        sx={{
          width: "90%",
          maxWidth: "720px",
          margin: "3rem auto",
          height: { xs: "100%", lg: "100vh" }
        }}
        alignItems="center"
      >
        <div>
          <Typography variant="h1" align="center">
            どちらで利用しますか？
          </Typography>
          <Typography align="center">登録後に変更はできません</Typography>
        </div>
        <SelectAccountSection />
        {activeState === "teacher" && (
          <FormProvider {...teacherSignupForm}>
            <form onSubmit={teacherSignupForm.handleSubmit(teacherSignup)}>
              <SignupForm variant="teacher" />
            </form>
          </FormProvider>
        )}
        {activeState === "school" && (
          <FormProvider {...schoolSignupForm}>
            <form onSubmit={schoolSignupForm.handleSubmit(schoolSignup)}>
              <SignupForm variant="school" />
            </form>
          </FormProvider>
        )}
      </Box>
    </AuthLayout>
  );
};

export default Signup;
