import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  schoolRecruitmentsAtom,
  kindOfSchoolRecruitmentsAtom
} from "store/schools/mypage/RecruitmentsStore";

const useMyRecruitmentsPageApi = () => {
  const currentSchool = useAtomValue(currentSchoolAtom);
  const [, setSchoolRecruitments] = useAtom(schoolRecruitmentsAtom);
  const [kindOfRecruitments, setKindOfSchoolRecruitments] = useAtom(
    kindOfSchoolRecruitmentsAtom
  );

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lesson_recruitments,
    {
      query: { status: kindOfRecruitments },
      onSuccess: (data) => {
        setSchoolRecruitments(data);
      },
      onError: () => {
        addErrorMessage("募集一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfRecruitments, setKindOfSchoolRecruitments };
};

export default useMyRecruitmentsPageApi;
