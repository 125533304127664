import React from "react";
import { useLocation } from "react-router-dom";

import CommonSidebarItem from "components/molecules/CommonSidebarItem";
import SidebarAccountInfo from "components/molecules/SidebarAccountInfo";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import HomeIcon from "assets/common/sidebar/selected/home.svg";
import RequestIcon from "assets/common/sidebar/selected/schools/request.svg";
import RecruitmentIcon from "assets/common/sidebar/selected/schools/recruitment.svg";
import LessonIcon from "assets/common/sidebar/selected/lesson.svg";
import ProfileEditIcon from "assets/common/sidebar/selected/edit.svg";
import HomeEmptyIcon from "assets/common/sidebar/unselected/home.svg";
import RequestEmptyIcon from "assets/common/sidebar/unselected/schools/request.svg";
import RecruitmentEmptyIcon from "assets/common/sidebar/unselected/schools/recruitment.svg";
import LessonEmptyIcon from "assets/common/sidebar/unselected/lesson.svg";
import ProfileEditEmptyIcon from "assets/common/sidebar/unselected/edit.svg";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import NoteAltOutlined from "@mui/icons-material/NoteAltOutlined";

import useSchoolAuthApi from "hooks/useSchoolAuthApi";
import AITeachingPlanLead from "../../features/schools/components/molecules/common/AITeachingPlanLead";

const SpDrawerContentsSchool: React.FC = () => {
  const theme = useTheme();
  const { logout } = useSchoolAuthApi();
  const location = useLocation();

  const isSelected = (link: string) => {
    const currentLink = location.pathname + location.search;
    return currentLink === link;
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box sx={{ backgroundColor: theme.palette.common.white }}>
        <SidebarAccountInfo type="school" />
        <List>
          <CommonSidebarItem
            text="マイページTOP"
            Icon={
              <img
                src={isSelected("/schools/mypage") ? HomeIcon : HomeEmptyIcon}
                alt="マイページTOP"
              />
            }
            link="/schools/mypage"
            selected={isSelected("/schools/mypage")}
          />
          <CommonSidebarItem
            text="新規募集作成"
            Icon={
              isSelected("/recruitments/new") ? (
                <NoteAltIcon sx={{ color: theme.palette.textColor.main }} />
              ) : (
                <NoteAltOutlined sx={{ color: theme.palette.textColor.main }} />
              )
            }
            link="/recruitments/new"
            selected={isSelected("/recruitments/new")}
          />
          <CommonSidebarItem
            text="直接依頼状況"
            Icon={
              <img
                src={
                  isSelected("/schools/mypage/requests")
                    ? RequestIcon
                    : RequestEmptyIcon
                }
                alt="直接依頼状況"
              />
            }
            link="/schools/mypage/requests"
            selected={isSelected("/schools/mypage/requests")}
          />
          <CommonSidebarItem
            text="募集状況"
            Icon={
              <img
                src={
                  isSelected("/schools/mypage/recruitments")
                    ? RecruitmentIcon
                    : RecruitmentEmptyIcon
                }
                alt="募集状況"
              />
            }
            link="/schools/mypage/recruitments"
            selected={isSelected("/schools/mypage/recruitments")}
          />
          <CommonSidebarItem
            text="授業進行管理"
            Icon={
              <img
                src={
                  isSelected("/schools/mypage/lessons")
                    ? LessonIcon
                    : LessonEmptyIcon
                }
                alt="授業進行管理"
              />
            }
            link="/schools/mypage/lessons"
            selected={isSelected("/schools/mypage/lessons")}
          />
          <Divider />
          <CommonSidebarItem
            text="プロフィール編集"
            Icon={
              <img
                src={
                  isSelected("/schools/profile/edit")
                    ? ProfileEditIcon
                    : ProfileEditEmptyIcon
                }
                alt="プロフィール編集"
              />
            }
            link="/schools/profile/edit"
            selected={isSelected("/schools/profile/edit")}
          />
          <CommonSidebarItem
            text="ログアウト"
            Icon={
              <ExitToAppIcon sx={{ color: theme.palette.textColor?.main }} />
            }
            onClick={logout}
          />
        </List>
      </Box>
      <AITeachingPlanLead />
    </Box>
  );
};

export default SpDrawerContentsSchool;
