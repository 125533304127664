import React from "react";
import { useAtom } from "jotai";
import Container from "@mui/material/Container";
import CommonLayout from "components/layouts/CommonLayout";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Meta from "components/organisms/Meta";

import Loading from "components/atoms/Loading";
import { useParams } from "react-router-dom";

import useShowPageApi from "features/schools/hooks/lessons/useShowPageApi";

import LessonFlowSection from "features/schools/components/organisms/mypage/lessons/organisms/LessonFlowSection";
import LessonInfoSection from "features/schools/components/organisms/mypage/lessons/organisms/LessonInfoSection";
import MeetingScheduleBlock from "features/schools/components/organisms/mypage/lessons/organisms/MeetingScheduleBlock";
import WaitingForMeetingDateSelectionBlock from "features/schools/components/organisms/mypage/lessons/organisms/WaitingForMeetingDateSelectionBlock";
import SetMeetingPlaceBlock from "components/organisms/lessons/SetMeetingPlaceBlock";
import BeforeLessonBlock from "features/schools/components/organisms/mypage/lessons/organisms/BeforeLessonBlock";
import PreparationList from "features/schools/components/organisms/mypage/lessons/organisms/PreparationList";
import AfterLessonBlock from "features/schools/components/organisms/mypage/lessons/organisms/AfterLessonBlock";
import ToRatingBlock from "features/schools/components/organisms/mypage/lessons/organisms/ToRatingBlock";
import Chat from "components/organisms/lessons/Chat";

import { detailsAtom } from "features/schools/store/lessonsStore";
import WaitingForCompleteReportBlock from "features/schools/components/organisms/mypage/lessons/organisms/WaitingForCompleteReportBlock";
import LessonCompletedBlock from "features/schools/components/organisms/mypage/lessons/organisms/LessonCompletedBlock";
import LessonPlaceBlock from "components/organisms/lessons/LessonPlaceBlock";
import BeforeMeetingBlock from "components/organisms/lessons/BeforeMeetingBlock";

import CustomTabSection from "components/organisms/CustomTabSection";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const { id } = useParams<paramsTypes>();
  const details = useAtom(detailsAtom)[0];
  const theme = useTheme();

  const {
    getLessonIsLoading,
    postRequestComments,
    deleteRequestComments,
    postMeetingSchedules,
    updateLesson,
    postRating
  } = useShowPageApi(id);

  const getTaskBlock = () => {
    switch (details?.step) {
      case "suggest_meeting_date":
        return (
          <MeetingScheduleBlock
            postLessonMeetingPossibleDatimes={postMeetingSchedules}
            updateLesson={updateLesson}
          />
        );
      case "select_meeting_date":
        return <WaitingForMeetingDateSelectionBlock />;
      case "adjust_meeting_place":
        return <SetMeetingPlaceBlock updateLesson={updateLesson} />;
      case "before_meeting":
        return <BeforeMeetingBlock />;
      case "preparation":
        (details?.is_online && !details?.lesson_url) ||
        (!details?.is_online && !details?.place) ? (
          <LessonPlaceBlock updateLesson={updateLesson} />
        ) : (
          <PreparationList />
        );
        return (
          <>
            {!details.is_skip_lesson_preliminary_report &&
              !details.lesson_preliminary_report_completed_at && (
                <BeforeLessonBlock updateLesson={updateLesson} />
              )}
            {(details?.is_online && !details?.lesson_url) ||
            (!details?.is_online && !details?.place) ? (
              <LessonPlaceBlock updateLesson={updateLesson} />
            ) : (
              <PreparationList />
            )}
          </>
        );
      case "lesson_end":
        if (
          !details.is_skip_after_lesson_report &&
          !details.after_lesson_report_completed_at
        ) {
          return <AfterLessonBlock updateLesson={updateLesson} />;
        }
        return <WaitingForCompleteReportBlock />;
      case "completed":
        return (
          <>
            <LessonCompletedBlock />
            {!details.has_rating && <ToRatingBlock />}
            {!details.is_skip_after_lesson_report &&
              !details.after_lesson_report_completed_at && (
                <AfterLessonBlock updateLesson={updateLesson} />
              )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Meta
        title="複業先生 | 授業詳細・進行管理"
        description="成立した授業の詳細情報と進行状況を確認するページです。"
      />
      <CommonLayout>
        {getLessonIsLoading ? (
          <Loading />
        ) : (
          <>
            {/* ここにコンポーネントを追加していく */}
            <LessonInfoSection />
            <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
              <Container
                maxWidth="lg"
                sx={{
                  width: "100%",
                  padding: { xs: 0, sm: "1.5rem" }
                }}
              >
                <CustomTabSection
                  displayIn="pc"
                  tabs={[
                    {
                      Icon: <EditNoteOutlinedIcon />,
                      labelName: "授業の進行管理",
                      queryName: "",
                      tabPanelContents: (
                        <>
                          {getTaskBlock()}
                          <LessonFlowSection
                            updateLesson={updateLesson}
                            postRating={postRating}
                          />
                        </>
                      )
                    },
                    {
                      Icon: <ChatOutlinedIcon />,
                      labelName: "学校とのメッセージ",
                      queryName: "chat",
                      tabPanelContents: (
                        <Chat
                          postRequestComments={postRequestComments}
                          deleteRequestComments={deleteRequestComments}
                          variant="school"
                        />
                      )
                    }
                  ]}
                />
                <Box
                  sx={{
                    display: { xs: "block", md: "none" }
                  }}
                >
                  <Chat
                    postRequestComments={postRequestComments}
                    deleteRequestComments={deleteRequestComments}
                    variant="school"
                  />
                </Box>
              </Container>
            </Box>
          </>
        )}
      </CommonLayout>
    </>
  );
};

export default Show;
