import React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";

import useIsNew from "hooks/useIsNew";
import { proposedDateTypes } from "types/lessonRecruitmentsTypes";

import {
  checkDeadline,
  formatDateString,
  formatDateTimeStringWithDay
} from "utils/date";
import NewRecruitmentLabel from "components/atoms/recruitmentLabels/NewRecruitmentLabel";
import ImportantInfoCard from "../../../../components/molecules/ImportantInfoCard";

type RecruitmentListItemProps = {
  id: number;
  title: string;
  schoolName: string;
  isOnline: boolean;
  tagName: string;
  schoolAddress?: string;
  recruitmentDeadAt: string;
  schoolGradeName: string;
  schoolMinutes: number;
  studentNumber: number;
  updatedAt: string;
  proposedDates: Array<proposedDateTypes>;
  step: string;
};

const RecruitmentListItem: React.FC<RecruitmentListItemProps> = (props) => {
  const {
    id,
    title,
    schoolName,
    isOnline,
    tagName,
    schoolAddress,
    recruitmentDeadAt,
    schoolMinutes,
    schoolGradeName,
    studentNumber,
    proposedDates,
    updatedAt,
    step
  } = props;
  const theme = useTheme();

  const { limit, dead } = checkDeadline(recruitmentDeadAt);

  const isNew = useIsNew(new Date(updatedAt));

  // TODO: このコンポーネントは繰り返し同じ箇所で使われているので、共通化する
  return (
    <Box
      component={Link}
      to={`recruitments/${id}`}
      key={id}
      sx={{
        width: "100%",
        backgroundColor: theme.palette.common.white,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border?.main}`,
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        textDecoration: "none",
        color: theme.palette.textColor.main
      }}
    >
      {isNew && <NewRecruitmentLabel flexStart />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold" fontSize="1.2rem">
            {title}
          </Typography>
          <Typography>{schoolName}</Typography>
          {proposedDates.map((proposedDate, index) => (
            <Typography>
              <Typography component="span" fontWeight="bold">
                第{index + 1}希望：
              </Typography>
              <span>
                {formatDateTimeStringWithDay(proposedDate.start_at_datetime)} (
                {schoolMinutes}分間)
              </span>
            </Typography>
          ))}
          <div>
            <LessonThemeChip name={tagName} size="small" />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <ImportantInfoCard
            Icon={DescriptionOutlinedIcon}
            title="実施方法"
            mainInfo={isOnline ? "オンライン" : "対面"}
            subInfo={isOnline ? undefined : schoolAddress}
          />
          <ImportantInfoCard
            Icon={PermIdentityIcon}
            title="対象生徒"
            mainInfo={schoolGradeName}
            subInfo={`${studentNumber}人`}
          />
          <ImportantInfoCard
            Icon={AccessTimeIcon}
            title="募集期限"
            mainInfo={
              step === "now_recruting" && !dead ? `あと${limit}日` : "募集終了"
            }
            subInfo={
              step === "now_recruting"
                ? formatDateString(recruitmentDeadAt)
                : "-----"
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecruitmentListItem;
