import { useEffect, useState } from "react";
import type { Swiper as SwiperCore } from "swiper";

import useTheme from "@mui/material/styles/useTheme";
import { useMediaQuery } from "@mui/material";

import useQueryParams from "./useQueryParams";

type PageTabSlidesProps = {
  tabList: Array<string>;
  resetTabAt: "all" | "sp" | "pc";
};

type QueryParamsType = {
  tab_name?: string;
};

// MEMO: page単位でtabを切り替える際に使用するhooks
export const usePageTabSlides = (props: PageTabSlidesProps) => {
  // MEMO: 複数ファイルで使おうとするとAtomに押し込まなくては行けなくなるため注意
  const { tabList, resetTabAt } = props;
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getQueryParams, updateQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const { tab_name } = getQueryParams(["tab_name"]);
  const tabIndex = tabList.indexOf(tab_name || "");

  const onTabSlidesChange = (event: React.SyntheticEvent, newValue: string) => {
    const index = tabList.indexOf(newValue);

    if (index !== -1) {
      if (index === 0) {
        deleteQueryParams("tab_name");
      } else {
        updateQueryParams("tab_name", tabList[index]);
      }
      swiper?.slideTo(index);
    }
  };

  const onSwiper = (currentSwiper: SwiperCore) => {
    const swiperInstance = currentSwiper;
    setSwiper(swiperInstance);

    if (tabIndex !== -1) {
      swiperInstance.slideTo(tabIndex);
    }
  };

  const onSlideChange = (currentSwiper: SwiperCore) => {
    if (currentSwiper.activeIndex === 0) {
      deleteQueryParams("tab_name");
    } else {
      updateQueryParams("tab_name", tabList[currentSwiper.activeIndex]);
    }
  };

  const resetTabSlides = () => {
    deleteQueryParams("tab_name");
    swiper?.slideTo(0);
  };

  useEffect(() => {
    switch (resetTabAt) {
      case "all":
        break;
      case "sp":
        if (!isMobile) {
          resetTabSlides();
        }
        break;
      case "pc":
        if (isMobile) {
          resetTabSlides();
        }
        break;
      default:
        break;
    }
  }, [isMobile]);

  return {
    tab_name,
    tabIndex,
    onTabSlidesChange,
    onSwiper,
    onSlideChange,
    resetTabSlides
  };
};
