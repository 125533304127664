import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import type React from "react";
import FormFullNameField from "components/molecules/FormFullNameField";
import { FormProvider } from "react-hook-form";
import FormTextField from "components/molecules/FormTextField";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { contactTypes } from "constants/selectOptions";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { Stack } from "@mui/material";
import useContactPageApi from "features/root/hooks/useContactPageApi";
import CommonLayout from "components/layouts/CommonLayout";

const Contact: React.FC = () => {
  const theme = useTheme();
  const { submitContact, form } = useContactPageApi();

  return (
    <CommonLayout>
      <Meta
        title="複業先生 | お問い合わせ"
        description="複業先生の使い方やシステムの利用についてなど、不明なことがありましたら、こちらのフォームからお問い合わせください。"
      />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="sm"
          sx={{
            padding: "1.5rem 1rem"
          }}
        >
          <Box
            sx={{
              marginBottom: "1.5rem"
            }}
          >
            <Typography variant="h1">お問い合わせ</Typography>
          </Box>
          <FormProvider {...form}>
            <Stack
              sx={{
                backgroundColor: theme.palette.common.white,
                boxSizing: "border-box"
              }}
              component="form"
              gap={2}
              p={2}
              onSubmit={form.handleSubmit(submitContact)}
            >
              <Typography variant="h2">必要事項をご入力ください。</Typography>
              <FormFullNameField label="氏名" isRequired />
              <FormTextField
                name="email"
                label="メールアドレス"
                isRequired
                placeholder="sample@fukugyo.co.jp"
                fullWidth
              />
              <FormRadioGroup
                name="contactType"
                isRequired
                label="お問い合わせの種類"
                options={contactTypes}
                row
              />
              <FormTextArea
                label="お問い合わせ内容"
                name="contactContents"
                isRequired
                placeholder="例：授業の日程が変更になってしまいました。どう対応すればいいでしょうか。"
              />
              <FormSubmitButton label="送信する" fullWidth />
            </Stack>
          </FormProvider>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default Contact;
