import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";
import useIsNew from "hooks/useIsNew";

import { proposedDateTypes } from "types/lessonRecruitmentsTypes";

import {
  checkDeadline,
  formatDateString,
  formatDateTimeStringWithDay
} from "utils/date";
import NewRecruitmentLabel from "components/atoms/recruitmentLabels/NewRecruitmentLabel";
import ImportantInfoCard from "../../../../components/molecules/ImportantInfoCard";

type RecruitmentListItemForGuestProps = {
  id: number;
  title: string;
  isOnline: boolean;
  tagName: string;
  schoolPrefectureName: string;
  schoolTypeName?: string;
  schoolDivisionName?: string;
  recruitmentDeadAt: string;
  schoolGradeName: string;
  schoolMinutes: number;
  studentNumber: number;
  updatedAt: string;
  proposedDates: Array<proposedDateTypes>;
  step: string;
};

const RecruitmentListItemForGuest: React.FC<
  RecruitmentListItemForGuestProps
> = (props) => {
  const {
    id,
    title,
    isOnline,
    tagName,
    schoolPrefectureName,
    schoolTypeName,
    schoolDivisionName,
    recruitmentDeadAt,
    schoolMinutes,
    schoolGradeName,
    studentNumber,
    proposedDates,
    updatedAt,
    step
  } = props;
  const theme = useTheme();
  const { limit } = checkDeadline(recruitmentDeadAt);

  const isNew = useIsNew(new Date(updatedAt));

  return (
    <Box
      key={id}
      sx={{
        width: "100%",
        backgroundColor: theme.palette.common.white,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border?.main}`,
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      {isNew && <NewRecruitmentLabel flexStart />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold" fontSize="1.2rem">
            {title}
          </Typography>
          <Typography>
            {schoolPrefectureName}
            {schoolTypeName}
            {schoolDivisionName}
          </Typography>
          {proposedDates.map((proposedDate, index) => (
            <Typography>
              <Typography component="span" fontWeight="bold">
                第{index + 1}希望：
              </Typography>
              <span>
                {formatDateTimeStringWithDay(proposedDate.start_at_datetime)} (
                {schoolMinutes}分間)
              </span>
            </Typography>
          ))}
          <div>
            <LessonThemeChip name={tagName} size="small" />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <ImportantInfoCard
            Icon={DescriptionOutlinedIcon}
            title="実施方法"
            mainInfo={isOnline ? "オンライン" : "対面"}
            subInfo={isOnline ? undefined : schoolPrefectureName}
          />
          <ImportantInfoCard
            Icon={PermIdentityIcon}
            title="対象生徒"
            mainInfo={schoolGradeName}
            subInfo={`${studentNumber}人`}
          />
          <ImportantInfoCard
            Icon={AccessTimeIcon}
            title="募集期限"
            mainInfo={step === "now_recruting" ? `あと${limit}日` : "募集終了"}
            subInfo={
              step === "now_recruting"
                ? formatDateString(recruitmentDeadAt)
                : "-----"
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecruitmentListItemForGuest;
