import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";
import React from "react";

import CommonLoading from "components/atoms/CommonLoading";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import LessonIcon from "assets/common/sidebar/unselected/lesson.svg";
import LessonItem from "../molecules/LessonItem";
import { teacherLessonsAtom } from "../store";

import NoContentItem from "../molecules/NoContentItem";

interface HomeLessonsProps {
  isLoading: boolean;
}

const HomeLessons: React.FC<HomeLessonsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const teacherLessons = useAtomValue(teacherLessonsAtom);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%"
      }}
    >
      <CommonMypageHomeSectionTitle
        Icon={<img src={LessonIcon} alt="授業進行管理" />}
        title="授業進行管理"
        count={teacherLessons?.data_count || 0}
        linkName="すべての授業を見る"
        link="/teachers/mypage/lessons"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Typography fontWeight="bold" sx={{ flex: 1 }}>
            授業
          </Typography>
          <Typography fontWeight="bold">ステータス</Typography>
        </Box>
        <Divider sx={{ width: "100%" }} />
        {!isLoading ? (
          teacherLessons && teacherLessons.data.length > 0 ? (
            teacherLessons.data.map((schoolLesson, index) => (
              <React.Fragment key={index}>
                <LessonItem
                  id={schoolLesson.details.id}
                  exactStartAt={new Date(schoolLesson.details.exact_start_at)}
                  schoolMinutes={schoolLesson.details.school_minutes}
                  schoolName={schoolLesson.send_user.school_name}
                  schoolImage={schoolLesson.send_user.image.url}
                  isCertification={schoolLesson.send_user.is_match_accepted}
                  step={schoolLesson.details.step || ""}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <NoContentItem
              title="まだ成立した授業はありません"
              lead="授業が成立すると、情報が表示されます"
            />
          )
        ) : (
          <CommonLoading />
        )}
      </Box>
    </Box>
  );
};

export default HomeLessons;
