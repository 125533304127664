import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import FormSubmitButton from "components/molecules/FormSubmitButton";

import type React from "react";
import { useFormContext } from "react-hook-form";
import { useAtom, useAtomValue } from "jotai";
import Button from "components/atoms/mui/Button";
import { Typography } from "@mui/material";
import {
  formatDateStringWithDay,
  formatDateTimeStringWithDay
} from "utils/date";
import { schoolGradesAtom, stepAtom, tagsAtom } from "../store";
import FieldsFrame from "./FieldsFrame";
import CommonInfoContent from "../../../../components/molecules/CommonInfoContent";

const Step02: React.FC = () => {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const tags = useAtomValue(tagsAtom);
  const schoolGrades = useAtomValue(schoolGradesAtom);
  const [, setStep] = useAtom(stepAtom);
  const proposedDates = getValues("proposed_dates");
  const deadLine = formatDateStringWithDay(getValues("recruitment_dead_at"));
  const tagName = tags?.find(
    (tag) => tag.id.toString() === getValues("tag_id").toString()
  )?.name;
  const schoolGradeName = schoolGrades.find(
    (schoolGrade) =>
      schoolGrade.id.toString() === getValues("school_grade_id").toString()
  )?.name;
  const handleReturn = () => {
    setStep(1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          padding: { xs: "0 1.5rem", sm: 0 }
        }}
      >
        <Typography variant="h2">募集内容</Typography>
        <Typography>
          下記の内容で問題なければ、「募集を開始する」ボタンを押してください。変更がある場合は、戻って変更してください。
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          backgroundColor: theme.palette.common.white,
          padding: "1.5rem"
        }}
      >
        <FieldsFrame title="授業日程">
          {proposedDates.map((date: string, index: number) => {
            if (!date) return null;
            const content = formatDateTimeStringWithDay(date);
            return (
              <CommonInfoContent
                key={index}
                title={`授業候補日（第${index + 1}希望）`}
                content={content}
              />
            );
          })}
        </FieldsFrame>
        <Divider />

        <FieldsFrame title="募集について">
          <CommonInfoContent
            title="募集のタイトル"
            content={getValues("title")}
          />
          <CommonInfoContent title="募集締め切り日" content={deadLine} />
        </FieldsFrame>
        <Divider />

        <FieldsFrame title="授業内容">
          <CommonInfoContent
            title="授業時間"
            content={`${getValues("school_minutes")}分`}
          />
          <CommonInfoContent
            title="実施方法"
            content={getValues("is_online") ? "対面授業" : "オンライン授業"}
          />
          {(!getValues("is_online") || getValues("is_online") === "false") && (
            <CommonInfoContent
              title="交通費の取り扱い"
              content={getValues("travel_cost")}
            />
          )}
          <CommonInfoContent
            title="授業テーマ"
            content={tagName || "選択されていません"}
          />
          <CommonInfoContent
            title="授業内容"
            content={getValues("lesson_contents")}
            multiline
          />
          <CommonInfoContent
            title="こんな先生に来てほしい"
            content={getValues("want_to")}
            multiline
          />
        </FieldsFrame>
        <Divider />

        <FieldsFrame title="対象生徒（児童）">
          <CommonInfoContent
            title="学年"
            content={schoolGradeName || "選択されていません"}
          />
          <CommonInfoContent
            title="生徒数"
            content={`${getValues("student_number")}人`}
          />
          <CommonInfoContent
            title="生徒の今の様子"
            content={getValues("student_condition") || "未記入"}
            multiline
          />
          <CommonInfoContent
            title="今感じている課題"
            content={getValues("issue") || "未記入"}
            multiline
          />
        </FieldsFrame>
        <Divider />

        <FieldsFrame>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
              alignItems: "center"
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReturn}
              sx={{
                width: "12rem"
              }}
            >
              戻る
            </Button>
            <FormSubmitButton label="募集を開始する" sx={{ width: "12rem" }} />
          </Box>
        </FieldsFrame>
      </Box>
    </Box>
  );
};

export default Step02;
