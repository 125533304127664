import React from "react";
import { useAtomValue } from "jotai";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { detailsAtom } from "features/schools/store/lessonsStore";

const LessonCompletedBlock: React.FC = () => {
  const theme = useTheme();
  const details = useAtomValue(detailsAtom);

  // 授業実施日の翌月末を取得
  const claimTiming = dayjs(details?.exact_start_at)
    .locale(ja)
    .add(1, "month")
    .endOf("month")
    .format("M月");

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        boxSizing: "border-box"
      }}
    >
      <Typography variant="h2">ご利用ありがとうございました！</Typography>
      <Typography>
        この度は複業先生をご利用いただきありがとうございました。
      </Typography>
      <Typography>
        {claimTiming}中に、請求書をメールで送付するので、ご対応お願いします。
      </Typography>
    </Box>
  );
};

export default LessonCompletedBlock;
